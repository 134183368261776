import { Action, createReducer, on } from "@ngrx/store";
import { PaymentInfo } from "src/app/core/interfaces/Payment-Info";
import { updatePaymentInfo } from "../actions/payment-info.actions";
import { clearEntries } from "../actions/can-navigate.action";

export const initialPaymentInfoState: PaymentInfo = {
    nonDigitalPayment: false,
    name: "",
    creditCard: "",
    expirationDate: "",
    cvc: "",
    zip: ""
};

const paymentInfoReducer = createReducer(
    initialPaymentInfoState,
    on(updatePaymentInfo, (state, { data }) => ({ ...state, ...data })),
    on(clearEntries, (state, action) => ({ ...state, ...initialPaymentInfoState }))
);

export function paymentInfoReducers(state = initialPaymentInfoState, action: Action) {
    return paymentInfoReducer(state, action);
}