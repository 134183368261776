import { createAction, props } from '@ngrx/store';
import { AdditionalItem, AdditionalItemPurchase } from 'src/app/core/interfaces/Additional-Item';

export const getCheckoutPrice = createAction(
    '[Entry Checkout] Get',
    props<{ data: any }>()
);

export const getCheckoutPriceSuccess = createAction(
    '[Entry Checkout] Get Success',
    props<{ data: any }>()
);

export const getCheckoutPriceFailure = createAction(
    '[Entry Checkout] Get Failure',
    props<{ error: string }>()
);


// Submit Checkout Actions
export const submitCheckout = createAction(
    '[Entry Submit] Checkout'
)

export const submitCheckoutSuccess = createAction(
    '[Entry Submit] Checkout Success',
    props<{ data: any }>()
);

export const submitCheckoutFailure = createAction(
    '[Entry Submit] Checkout Failure',
    props<{ error: string }>()
);
