
import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import * as fromRolloverEntries from '../reducers/rolloverEntries.reducers'

export const selectRolloverEntriesState = (state: AppState) => state.rolloverEntries;

export const selectRolloverEntries = createSelector(
    selectRolloverEntriesState,
    fromRolloverEntries.selectAll
);

export const selectRolloverEntriesEntities = createSelector(
    selectRolloverEntriesState,
    fromRolloverEntries.selectEntities
);

export const selectRolloverEntriesByID = (ids: any[]) => createSelector(
    selectRolloverEntriesEntities,
    entities => ids.map(id => entities[id])
);