import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContestantEntry, Horse, HorseEntry, SidepotEntry } from 'src/app/core/interfaces/Entry';
import { EventData } from 'src/app/core/interfaces/EventData';
import { EventClass } from 'src/app/core/interfaces/EventClass';
import { addClassDateEntry, addEntry, removeEntry, updateClassHorseName } from 'src/app/store/actions/entries.actions';
import { AppState } from 'src/app/store/reducers/AppState';
import { from, groupBy, mergeMap, toArray } from 'rxjs';
import * as uuid from "uuid";

@Component({
  selector: 'mef-entry-class',
  templateUrl: './class.component.html',
  styleUrls: ['./class.component.scss']
})
export class ClassComponent implements OnInit {

  @Input() item: EventClass;
  @Input() entries: any = [];
  @Input() eventData: EventData;
  @Input() sidepotEntries: any;
  @Input() rolloverEntries: any;
  @Input() classDateEntries: any;
  @Input() contestant: ContestantEntry;

  @Input() horse: Horse;
  @Input() currentHorseName: string;

  @Input() set horseName(value: any) {
    this.getClassEntries(this.item._id).map((horseEntry: any) => {
      this.currentHorseName = value;
      this.updateHorseName(horseEntry, value);
    })

  };



  constructor(private store: Store<AppState>) {
  }

  getClassEntries(classId: any) {
    if (this.entries) {
      if (this.entries[classId]) {
        return this.entries[classId].filter((entry: { horseName: string; }) => entry.horseName === this.currentHorseName);
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  ngOnInit(): void {
  }

  addEntryToClass(classId: any) {
    const horseEntryId = uuid.v4();

    this.store.dispatch(addEntry({ id: horseEntryId, contestantId: this.contestant.id, classId: classId, horseName: this.currentHorseName, horseId: this.horse.id }));

    // This is so when there is only one date avaiable for the class we add that to the store
    // so that proper fees are taken out
    if (this.item.prices.length == 1) {
      this.addDateSelectionEntry(horseEntryId, classId, this.item.prices[0]._id, this.item.prices[0].date);
    }

  }
  
  addDateSelectionEntry(horseEntryId: string, classId: string, priceId: string, date: string) {
    this.store.dispatch(addClassDateEntry({ contestantId: this.contestant.id, entryId: horseEntryId, classId: classId, priceId: priceId, date: date, horseId: this.horse.id }));
  }

  removeEntry(entryId: string) {
    this.store.dispatch(removeEntry({ entryId: entryId }))
  }

  updateHorseName(entryData: any, value: string) {
    const update = {
      id: entryData.id,
      changes: {
        horseName: value
      }
    }
    this.store.dispatch(updateClassHorseName({ update: update }));
  }

  custom(index: number, item: any) {
    return index;
  }

}
