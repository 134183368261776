<div class="space-y-4 my-4">
  <div *ngFor="let class of classesByContestant[contestant.id] | async">
    <div class="flex space-x-6">
      <div class="flex flex-auto flex-col w-full">
        <div class="mb-4">
          <h4 class="font-medium text-gray-900">
            {{class.name}}
          </h4>
          <p *ngIf="class.description.length > 0" class="mt-2 text-sm text-gray-600">{{class.description}}</p>
        </div>


        <div>
          <div class="mx-auto grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-8">
            <div class="divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm"
              *ngFor="let entry of classEntries[class._id + contestant.id]">
              <div class="p-6">

                <div class="border-b border-gray-400 px-4 py-2">
                  <h4 class="text-md font-medium text-gray-900">
                    {{entry.horseName}}
                  </h4>
                </div>


                <div class="py-4 px-4 divide-y divide-gray-200">
                  <!-- Date Selections -->
                  <section *ngIf="!eventData.rodeo" aria-labelledby="class-date-heading" class="py-2">
                    <dl *ngFor="let price of class.prices;">
                      <div>
                        <div *ngIf="classDateEntries[entry.id + '' + price._id + '' + price.date]?.selected"
                          class="flex items-center justify-between">
                          <dt class="text-sm text-gray-600">{{class.multipleGos ? "Multiple Go's" : price.date | moment:'dddd'}}</dt>
                          <dd class="text-sm font-medium text-gray-900">{{price.price | currency}}</dd>
                        </div>
                      </div>
                    </dl>
                  </section>

                  <!-- Sidepots -->
                  <section *ngIf="class.sidePots.length > 0" aria-labelledby="sidepot-heading" class="py-2">
                    <dl *ngFor="let sidePot of class.sidePots;">
                      <div>
                        <div *ngIf="sidepotEntries[entry.id + '' + sidePot._id]?.selected"
                          class="flex items-center justify-between">
                          <dt class="text-sm text-gray-600">{{sidePot.name}}</dt>
                          <dd class="text-sm font-medium text-gray-900">{{sidePot.price | currency}}</dd>
                        </div>
                      </div>
                    </dl>
                  </section>

                  <!-- Rollovers -->
                  <section *ngIf="class.rolloverOptions.length > 0" aria-labelledby="rollover-heading" class="py-2">
                    <dl *ngFor="let rollover of class.rolloverOptions;">
                      <div>
                        <div *ngIf="rolloverEntries[entry.id + '' + rollover._id]?.selected"
                          class="flex items-center justify-between">
                          <dt class="text-sm text-gray-600">Roll to {{rollover.rollTo.name}}</dt>
                          <dd class="text-sm font-medium text-gray-900">{{rollover.price | currency}}</dd>
                        </div>
                      </div>
                    </dl>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>