import { Action, createReducer, on } from "@ngrx/store";
import { setCanNavigate } from "../actions/can-navigate.action";

export const initialCanNavigate = {
    status: false,
};

const canNavigateReducer = createReducer(
    initialCanNavigate,
    on(setCanNavigate, (state, { status }) => ({ ...state, status: status })),
);

export function canNavigateReducers(state = initialCanNavigate, action: Action) {
    return canNavigateReducer(state, action);
}