<div *ngIf="!item.drawSelection" class="flex items-center">
    <input (click)="updateSidepotEntry(horseId, item._id)" id="{{horseId}}{{item._id}}" [checked]="checked" name="{{horseId}}{{item._id}}"
        value="true" type="checkbox" class="focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded">
    
    
        <label for="{{horseId}}{{item._id}}" class="ml-3 block text-sm font-medium text-gray-700">
        {{item.name}} {{item['price'] | currency}}
    </label>
</div>

<div *ngIf="item.drawSelection" class="flex items-center">
    <input (click)="updateSidepotEntry(horseId, item._id)" id="{{horseId}}{{item._id}}" [checked]="checked" name="{{horseId}}{{classId}}-drawSelection"
        value="true" type="radio" class="focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded">
    
    
        <label for="{{horseId}}{{item._id}}" class="ml-3 block text-sm font-medium text-gray-700">
        {{item.name}}
    </label>
</div>