
import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import * as fromHorses from '../reducers/horses.reducers';

export const selectHorsesEntriesState = (state: AppState) => state.horses;

export const selectHorses = createSelector(
    selectHorsesEntriesState,
    fromHorses.selectAll
);
