import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { HorseEntry } from "src/app/core/interfaces/Entry";
import { addEntry, addManyEntries, removeContestantEntry, removeEntry, removeHorse, updateClassHorseName } from "../actions/entries.actions";
import { clearEntries } from "../actions/can-navigate.action";

export interface ClassEntriesState extends EntityState<HorseEntry> { }

export const classEntriesAdapter: EntityAdapter<HorseEntry> = createEntityAdapter<HorseEntry>();
export const initialClassEntriesState: ClassEntriesState = classEntriesAdapter.getInitialState();
export const classEntriesReducer = createReducer(
    initialClassEntriesState,
    on(addEntry, (state, action) => classEntriesAdapter.addOne({ id: action.id, contestantId: action.contestantId, classId: action.classId, horseName: action.horseName, dates: [], horseId: action.horseId }, state)),
    on(removeEntry, (state, action) => classEntriesAdapter.removeOne(action.entryId, state)),
    on(updateClassHorseName, (state, action) => classEntriesAdapter.updateOne(action.update, state)),
    on(removeContestantEntry, (state, action) => classEntriesAdapter.removeMany(entity => entity.contestantId === action.contestantId, state)),
    on(removeHorse, (state, action) => classEntriesAdapter.removeMany(entity => entity.horseId === action.horseId, state)),
    on(clearEntries, (state, action) => classEntriesAdapter.removeAll({ ...state, id: null })),
    on(addManyEntries, (state, action) => classEntriesAdapter.upsertMany(action.entries, state))
    );

export const { selectAll, selectEntities } = classEntriesAdapter.getSelectors();