import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'mef-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  userFullName: string = "";

  constructor(private userservice: UserService) {
    this.userservice.user.subscribe(x => {
      this.userFullName = x.name;
    });
  }

  ngOnInit(): void {
  }

  userMenuOpen: boolean = false;

  toggleUserMenu() {
    this.userMenuOpen = !this.userMenuOpen;
  }


  logout() {
    this.userservice.logout();

  }

  settings() {

  }


}
