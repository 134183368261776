import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { EventData } from 'src/app/core/interfaces/EventData';
import { clearEntries, setCanNavigate } from 'src/app/store/actions/can-navigate.action';
import { getAllEventClasses } from 'src/app/store/actions/classes.actions';
import { getPaymentData } from 'src/app/store/actions/edit.actions';
import { getEvent } from 'src/app/store/actions/event.actions';
import { setIsEdit } from 'src/app/store/actions/is-edit.action';
import { AppState } from 'src/app/store/reducers/AppState';
import { selectEvent } from 'src/app/store/selectors/event.selectors';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  public eventData: EventData;

  constructor(private store: Store<AppState>, private route: ActivatedRoute, private router: Router) { 
  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    const eventId = routeParams.get('id');
    const paymentId = routeParams.get('paymentId');

    if (eventId) {
      localStorage.setItem('eventId', eventId);
    }
    this.store.dispatch(clearEntries());
    
    this.store.dispatch(getEvent({ id: eventId }));
    this.store.dispatch(getAllEventClasses({ id: eventId }));
    this.store.dispatch(setCanNavigate({status: true}));
    this.store.dispatch(setIsEdit({status: true, paymentId: paymentId}));

    this.store.pipe(select(selectEvent)).subscribe(data => {
      this.eventData = data;
      this.store.dispatch(getPaymentData({id: paymentId, isRodeo: data.rodeo}))
    })
 

  }

  continueToEntryForm() {
    this.router.navigate(['/entry']);
  }

  isLive() {

    //Is opening time before current date time
    // if (moment.utc(this.event['eventData']['openingDateTime']).local().isBefore()) {


    //   //is closing time before current date time
    //   if (moment().isAfter(moment.utc(this.event['eventData']['closingDateTime']).local())) {

    //     return false;

    //   } else {

    //     return true;
    //   }

    // } else {

    //   return false;

    // }

    return true;
  }


}
