import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { CheckoutPriceResponse } from 'src/app/core/interfaces/Checkout-Price';
import { EventFee } from 'src/app/core/interfaces/Event-Fee';
import { AppState } from 'src/app/store/reducers/AppState';
import { selectCheckoutPriceResult } from 'src/app/store/selectors/checkout-price.selectors';
import { selectEventFees } from 'src/app/store/selectors/event-fees.selectors';

@Component({
  selector: 'mef-bottom-navigation',
  templateUrl: './bottom-navigation.component.html',
  styleUrls: ['./bottom-navigation.component.scss']
})
export class BottomNavigationComponent implements OnInit {

  checkoutPriceResult: CheckoutPriceResponse;

  @Input() isInvalid: boolean = false;
  @Input() backLabel: string = "Back";
  @Input() displayTotals: boolean = false;
  @Input() additionalText: string;
  @Input() paidEvent: boolean = true;
  @Input() disableButtons: boolean = false;
  @Input() rodeo: boolean = false;
  @Input() bbrBarrel: boolean = false;

  
  @Output() onContinue = new EventEmitter();
  @Output() onBack = new EventEmitter();

  constructor(private store: Store<AppState>) { 
    this.store.select(selectCheckoutPriceResult).subscribe(data => {
      this.checkoutPriceResult = data;
    })
  }

  ngOnInit(): void {
  }

  continue() {
    this.onContinue.emit();
  }

  back() {
    this.onBack.emit();
  }

}
