import { createAction, props } from '@ngrx/store';
import { PaymentData } from 'src/app/core/interfaces/Entry';

export const getPaymentData = createAction(
    '[Edit Get Payment Data] Get',
    props<{ id: any, isRodeo: boolean }>()
);

export const getPaymentDataSuccess = createAction(
    '[Edit Get Payment Data] Get Success',
    props<{ data: PaymentData; }>()
);

export const getPaymentDataFailure = createAction(
    '[Edit Get Payment Data] Get Failure',
    props<{ error: string }>()
);