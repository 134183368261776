import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { EntryComponent } from './components/entry/entry.component';
import { EventComponent } from './components/event/event.component';
import { PaymentMethodComponent } from './components/payment-method/payment-method.component';
import { ReceiptComponent } from './components/receipt/receipt.component';
import { ReviewComponent } from './components/review/review.component';
import { NavGuard } from './core/helpers/canNavigate.guard';
import { EditComponent } from './components/edit/edit.component';
import { AuthGuard } from './core/guards';

const routes: Routes = [
  {
    path: 'edit/:id/:paymentId',
    component: EditComponent
  },
  {
    path: 'event/:id',
    component: EventComponent
  },
  {
    path: 'admin/:id',
    component: EventComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'contact',
    component: ContactInfoComponent,
    canActivate: [NavGuard]
  },
  {
    path: 'entry',
    component: EntryComponent,
    canActivate: [NavGuard]
  },
  {
    path: 'review',
    component: ReviewComponent,
    canActivate: [NavGuard]
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
    canActivate: [NavGuard]
  },
  {
    path: 'receipt',
    component: ReceiptComponent,
    canActivate: [NavGuard]
  },
  {
    path: 'payment-method',
    component: PaymentMethodComponent,
    canActivate: [NavGuard]
  }
  // { path: '',   redirectTo: '/event', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
