import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CreditCardValidators } from 'angular-cc-library';
import { submitCheckout } from 'src/app/store/actions/checkout.actions';
import { updatePaymentInfo } from 'src/app/store/actions/payment-info.actions';
import { AppState } from 'src/app/store/reducers/AppState';
import { selectEvent } from 'src/app/store/selectors/event.selectors';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  
  eventData: any = {};
  ccForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router) { 

    this.store.select(selectEvent).subscribe(eventData => {
      this.eventData = eventData;
    });

    this.ccForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      creditCard: ['', [CreditCardValidators.validateCCNumber]],
      expirationDate: ['', [CreditCardValidators.validateExpDate]],
      cvc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
      zip: ['', [Validators.required, Validators.pattern("^\\d{5}(-{0,1}\\d{4})?$")]]
    });


  }

  ngOnInit(): void {
  }

  submit() {

    this.store.dispatch(updatePaymentInfo({ data: this.ccForm.value}));

    if (this.ccForm.valid) {
      this.store.dispatch(submitCheckout());      
    }

  }

  backToReview() {
    this.router.navigate(['/review']);
  }

}
