import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventData } from '../interfaces/EventData';
import { EventClass } from '../interfaces/EventClass';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class EventService {

  constructor(private http: HttpClient) { }

  getEvent(id: any) {
    return this.http.get<EventData>(environment.apiUrl + 'event/' + id);
  }

  //Classes within an event
  getAllEventClasses(eventId: string) {
    return this.http.get<EventClass[]>(environment.apiUrl + 'classes/event/' + eventId);
  }

  getAllEventAdditionalItems(id: any) {
    return this.http.get<[]>(environment.apiUrl + 'additionalitem/event/' + id);
  }

  getAllEventFees(id: any) {
    return this.http.get(environment.apiUrl + 'event/fees/' + id);
  }
  


}
