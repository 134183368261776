import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContestantEntry, Horse } from 'src/app/core/interfaces/Entry';
import { addRolloverEntry, removeClassRolloverEntries, removeRolloverEntry } from 'src/app/store/actions/entries.actions';
import { AppState } from 'src/app/store/reducers/AppState';
import { selectClassDateEntriesByContestantId } from 'src/app/store/selectors/classDateEntries.selectors';
import { NotifierService } from 'angular-notifier';
import { selectClassEntriesByIDs } from 'src/app/store/selectors/entries.selectors';

@Component({
  selector: 'mef-entry-rollover',
  templateUrl: './rollover.component.html',
  styleUrls: ['./rollover.component.scss']
})
export class RolloverComponent implements OnInit {


  @Input() horseId: string;
  @Input() item: any;
  @Input() checked: boolean;
  @Input() classId: string;
  @Input() horse: Horse;
  @Input() horseName: string;
  @Input() multipleGos: boolean;
  @Input() contestant: ContestantEntry;
  private readonly notifier: NotifierService;

  constructor(private store: Store<AppState>, notifierService: NotifierService) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
  }

  updateRolloverEntry(horseEntryId: string, rolloverOptionId: string, event: any, rollToName: string) {
    if (this.checked) {
      this.removeRolloverEntry(horseEntryId, rolloverOptionId);
    } else {
      this.addRolloverEntry(horseEntryId, rolloverOptionId, this.classId, event, rollToName);
    }
  }

  addRolloverEntry(horseEntryId: string, rolloverOptionId: string, classId: string, event: any, rollToName: string) {

    const validation = this.store.select(selectClassDateEntriesByContestantId(this.contestant.id)).subscribe(data => {

      const hasClassAndDateSelectionRollTo = data.filter(entry => (entry.date === this.item.date && entry.classId === this.item.rollTo['_id']));
      const hasClassAndDateSelectionParent = data.filter(entry => (entry.date === this.item.date && entry.classId === this.item.class));

      if (hasClassAndDateSelectionParent.length || this.multipleGos) {

        if (hasClassAndDateSelectionRollTo.length) {
          const nameValidation = this.store.select(selectClassEntriesByIDs(hasClassAndDateSelectionRollTo.map(entry => entry.entryId))).subscribe(classEntries => {

            let stop = true;
            for (let i = 0; i < classEntries.length; i++) {
              //@ts-ignore
              if (this.horseName == classEntries[i]['horseName']) {
                this.store.dispatch(removeClassRolloverEntries({horseId: horseEntryId}));
                this.store.dispatch(addRolloverEntry({ contestantId: this.contestant.id, horseId: horseEntryId, entryId: horseEntryId, rolloverOptionId: rolloverOptionId, classId: classId }));
                stop = false;
              }

            }

            if (stop) {
              event.preventDefault();
              this.notifier.notify('warning', `You horse name must match on this class and ${rollToName} to rollover.`);
            }

          });

          nameValidation.unsubscribe();
        } else {
          event.preventDefault();
          this.notifier.notify('warning', `You must enter into ${rollToName} to rollover.`);
        }
      } else {
        event.preventDefault();
        this.notifier.notify('warning', `You must enter into the date you are attempting to rollover.`);
      }

    });

    validation.unsubscribe();

  }

  removeRolloverEntry(horseEntryId: string, rolloverOptionId: string) {
    this.store.dispatch(removeRolloverEntry({ entryId: horseEntryId, rolloverOptionId: rolloverOptionId }));
  }

}
