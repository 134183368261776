import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { SidepotEntry } from "src/app/core/interfaces/Entry";
import { addManySidepotEntries, addSidepotEntry, removeContestantEntry, removeEntry, removeHorse, removeSidepotDrawSelections, removeSidepotEntry } from "../actions/entries.actions";
import { clearEntries } from "../actions/can-navigate.action";

export interface SidepotEntriesState extends EntityState<SidepotEntry> { }

export const sidepotEntriesAdapter: EntityAdapter<SidepotEntry> = createEntityAdapter<SidepotEntry>();
export const initialSidepotEntriesState: SidepotEntriesState = sidepotEntriesAdapter.getInitialState();
export const sidepotEntriesReducer = createReducer(
    initialSidepotEntriesState,
    on(addSidepotEntry, (state, action) => sidepotEntriesAdapter.addOne({ id: action.entryId + "" + action.sidePotId, contestantId: action.contestantId, entryId: action.entryId, sidePotId: action.sidePotId, classId: action.classId, selected: true, horseId: action.horseId, drawSelection: action.drawSelection }, state)),
    on(removeEntry, (state, action) => sidepotEntriesAdapter.removeMany(entity => entity.entryId === action.entryId, state)),
    on(removeSidepotEntry, (state, action) => sidepotEntriesAdapter.removeOne(action.entryId + "" + action.sidePotId, state)),
    on(removeContestantEntry, (state, action) => sidepotEntriesAdapter.removeMany(entity => entity.contestantId === action.contestantId, state)),
    on(removeHorse, (state, action) => sidepotEntriesAdapter.removeMany(entity => entity.horseId === action.horseId, state)),
    on(clearEntries, (state, action) => sidepotEntriesAdapter.removeAll({ ...state, id: null })),
    on(addManySidepotEntries, (state, action) => sidepotEntriesAdapter.upsertMany(action.entries, state)),
    on(removeSidepotDrawSelections, (state, action) => sidepotEntriesAdapter.removeMany(entity => entity.entryId === action.horseEntryId && entity.drawSelection === true, state)),    
);

export const { selectAll, selectEntities } = sidepotEntriesAdapter.getSelectors();