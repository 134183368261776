import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { map, catchError, exhaustMap, withLatestFrom, switchMap, tap, mergeMap } from 'rxjs/operators';
import { EntryService } from 'src/app/core/services/entry.service';
import { EventService } from 'src/app/core/services/event.service';
import { getAllEventAdditionalItemsSuccess } from '../actions/additional-items.actions';
import { getCheckoutPrice, getCheckoutPriceFailure, getCheckoutPriceSuccess, submitCheckout, submitCheckoutFailure, submitCheckoutSuccess } from '../actions/checkout.actions';
import { getAllEventClasses, getAllEventClassesSuccess, getEventClassesFailure } from '../actions/classes.actions';
import { AppState } from '../reducers/AppState';
import { selectCheckoutData } from '../selectors/checkout.selectors';
import { selectContactInfo } from '../selectors/contact-info.selector';
import { selectEvent } from '../selectors/event.selectors';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class EntryEffects {
    private readonly notifier: NotifierService;

    constructor(
        private actions$: Actions,
        private eventService: EventService,
        private entryService: EntryService,
        private store: Store<AppState>,
        private spinner: NgxSpinnerService,
        notifierService: NotifierService
    ) {
        this.notifier = notifierService;
    }



    getEventClasses$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAllEventClasses),
            exhaustMap(action =>
                this.eventService.getAllEventClasses(action.id).pipe(
                    map(classes => getAllEventClassesSuccess({ classes })),
                    catchError((error: any) => of(getEventClassesFailure(error))))
            )
        )
    });

    getAdditionalItems$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAllEventClasses),
            exhaustMap(action =>
                this.eventService.getAllEventAdditionalItems(action.id).pipe(
                    map( items => getAllEventAdditionalItemsSuccess({ items })),
                    catchError((error: any) => of(getEventClassesFailure(error))))
            )
        )
    });

    getCheckoutPrice$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getCheckoutPrice),
            exhaustMap(action =>
                this.entryService.getCheckoutPrice(action.data).pipe(
                    map( data => getCheckoutPriceSuccess({ data })),
                    catchError((error: any) => of(getCheckoutPriceFailure(error))))
            )
        )
    });

    submitCheckout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(submitCheckout),
            tap(_ => {
                this.spinner.show();
            }),
            withLatestFrom(this.store.select(selectCheckoutData)),
            mergeMap(([actions, data]) =>
                this.entryService.add(data).pipe(
                    map(data => submitCheckoutSuccess({ data })),
                    tap(_ => {
                        this.spinner.hide();
                    }),
                    catchError((error: any) => of(submitCheckoutFailure({error: error})).pipe(tap(error => {
                        this.spinner.hide();
                        this.notifier.notify('error', error.error);
                    }))))
            )
        )
    });

}