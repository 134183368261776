<div class="flex items-center">
    <input (click)="updateDateSelectionEntry(horseId, classId, item._id, item.date)" id="{{horseId}}{{item._id}}" [checked]="checked"
        name="{{horseId}}{{item._id}}" value="true" type="checkbox" disabled="{{disabled}}"
        class="focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded">


    <label *ngIf="!classData['multipleGos']" for="{{horseId}}{{item._id}}" class="ml-3 block text-sm font-medium text-gray-700">
        {{item.date | moment:'dddd'}} {{item['price'] | currency}}
    </label>
    <label *ngIf="classData['multipleGos']" for="{{horseId}}{{item._id}}" class="ml-3 block text-sm font-medium text-gray-700">
        Multiple Go's {{item['price'] | currency}}
    </label>
</div>