import { Component, OnInit, Input } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ContestantEntry } from 'src/app/core/interfaces/Entry';
import { EventData } from 'src/app/core/interfaces/EventData';
import { addContestantEntry } from 'src/app/store/actions/entries.actions';
import { AppState } from 'src/app/store/reducers/AppState';
import { selectContestantEntries } from 'src/app/store/selectors/contestant-entries.selectors';
import * as uuid from "uuid";

@Component({
  selector: 'mef-entry-contestants',
  templateUrl: './contestants.component.html',
  styleUrls: ['./contestants.component.scss']
})
export class ContestantsComponent implements OnInit {
  @Input() eventData: EventData;
  contestants$: Observable<ContestantEntry[]>;

  constructor(private store: Store<AppState>, private actions$: Actions) { 
    this.contestants$ = this.store.select(selectContestantEntries);
  }

  ngOnInit(): void {
  }

  addContestant() {
    this.store.dispatch(addContestantEntry({contestantId: uuid.v4()}));
  }

  custom(index: number, item: any) {
    return index;
  }

}
