
import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import * as fromClassDateEntries from '../reducers/classDates.reducers'
import { ClassDateEntry } from 'src/app/core/interfaces/Entry';

export const selectClassDateEntriesState = (state: AppState) => state.classDateEntries;

export const selectClassDateEntries = createSelector(
    selectClassDateEntriesState,
    fromClassDateEntries.selectAll
);

export const selectClassDateEntriesEntities = createSelector(
    selectClassDateEntriesState,
    fromClassDateEntries.selectEntities
);

export const selectClassDateEntriesByID = (ids: any[]) => createSelector(
    selectClassDateEntriesEntities,
    entities => ids.map(id => entities[id])
);

export const selectClassDateEntriesByClassEntryIds = (classEntryIds: string[]) =>
    createSelector(
        selectClassDateEntries,
        (dateSelection: ClassDateEntry[]) =>
            classEntryIds.map(id => (dateSelection.find(i => i.entryId === id) ? true : false))
    );


export const selectClassDateEntriesByContestantId = (contestantId: string) =>
    createSelector(
        selectClassDateEntries,
        (dateSelection: ClassDateEntry[]) => dateSelection.filter(i => i.contestantId === contestantId)
    );