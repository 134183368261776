<div class="px-2 rounded-lg border-1 border border-gray-200">
    <div class="flex items-center justify-between gap-x-6 pt-4">
        <p class="text-sm font-medium text-gray-900">
            Horse #{{horseNumber}}
        </p>
        <button (click)="removeHorse()" type="button"
            class="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]">
            <span class="sr-only">Dismiss</span>
            <svg class="h-5 w-5 text-gray" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                    d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
            </svg>
        </button>
    </div>
    <form [formGroup]="horseInfo" [connectForm]="horse">
        <div class="grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-8 mt-4">
            <div class="col-span-2 sm:col-span-3">
                <label for="name" class="block text-sm font-medium text-gray-700">Horse Name</label>
                <div class="mt-1">
                    <input type="text" name="name" id="name" formControlName="name"
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm">
                </div>
                <div
                    *ngIf="!horseInfo.get('name')?.valid && (horseInfo.get('name')?.dirty ||horseInfo.get('name')?.touched)">
                    <p [hidden]="!horseInfo.get('name').errors?.required" class="mt-1 text-sm text-red-600"
                        id="name-error">
                        Name is required</p>
                    <p [hidden]="!horseInfo.get('name').errors?.minlength" class="mt-1 text-sm text-red-600"
                        id="name-error">
                        Name must be longer than 3 letters</p>
                    <p [hidden]="!horseInfo.get('name').errors?.pattern" class="mt-1 text-sm text-red-600"
                        id="name-error">
                        Name must only contain letters and spaces</p>
                </div>
            </div>

           </div>
    </form>
    
    <mef-entry-classes [contestant]="item" [eventData]="eventData" [horse]="{id: this.horse.id, contestantId: item.id, name: horseInfo.get('name').value}"></mef-entry-classes>

</div>