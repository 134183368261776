import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { AdditionalItem } from "src/app/core/interfaces/Additional-Item";
import { getAllEventAdditionalItemsSuccess } from "../actions/additional-items.actions";

export interface AdditionalItemsState extends EntityState<AdditionalItem> { }

export const additionalItemsAdapter: EntityAdapter<AdditionalItem> = createEntityAdapter<AdditionalItem>({
  selectId: (eventClass: AdditionalItem) => eventClass._id
});

export const initialClassesState: AdditionalItemsState = additionalItemsAdapter.getInitialState();

export const additionalItemsReducer = createReducer(
  initialClassesState,
  on(getAllEventAdditionalItemsSuccess, (state, action) => additionalItemsAdapter.setAll(action.items, state))
);

export const { selectAll } = additionalItemsAdapter.getSelectors();