import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, exhaustMap, tap } from 'rxjs/operators';
import { EntryService } from 'src/app/core/services/entry.service';
import { NotifierService } from 'angular-notifier';
import { getPaymentData, getPaymentDataFailure, getPaymentDataSuccess } from '../actions/edit.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import { addManyContestantEntries, addManyEntries, addManySidepotEntries } from '../actions/entries.actions';
import { addManyAdditionalItemPurchases } from '../actions/additional-items.actions';
import { updateContactInfo } from '../actions/contact-info.actions';

@Injectable()
export class EditEffects {
    private readonly notifier: NotifierService;

    constructor(
        private actions$: Actions,
        private entryService: EntryService,
        notifierService: NotifierService,
        private store: Store<AppState>
    ) {
        this.notifier = notifierService;
    }



    getEntryData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getPaymentData),
            exhaustMap(action =>
                this.entryService.getEntry(action.id).pipe(
                    map(data => getPaymentDataSuccess({ data })),
                    tap(data => {

                        const entryData = data.data.entries.map((entry: any) => ({
                            contestantEntry: {
                                id: entry._id,
                                name: entry.name,
                                age: entry.age,
                                district: entry.district,
                                memberId: entry.memberId,
                                bbrMemberNumber: entry.bbrMemberNumber
                            },
                            sidepotEntries: entry.sidePots.map((sidepot: any) => ({
                                id: sidepot._id + sidepot.sidePot,
                                entryId: sidepot.entry,
                                contestantId: entry._id,
                                sidePotId: sidepot.sidePot,
                                classId: sidepot.class,
                                selected: true,
                                name: sidepot.name
                            })),
                            additionalItemPurchases: entry.additionalItems.map((item: any) => ({
                                id: entry._id + item.item,
                                itemId: item.item,
                                contestantId: entry._id,
                                amount: item.amount
                            }))
                        }));


                        this.store.dispatch(updateContactInfo({
                            data: {
                                name: data.data.name,
                                address: data.data.address,
                                city: data.data.city,
                                state: data.data.state,
                                zip: data.data.zip,
                                phone: data.data.phone,
                                email: data.data.email,
                            }
                        }))

                        entryData.map((entry: any) => {
                            this.store.dispatch(addManyContestantEntries({ entries: [entry.contestantEntry] }));

                            //If event is a rodeo all the events are sidepots so there wont be any
                            //Class entries
                            this.store.dispatch(addManySidepotEntries({ entries: entry.sidepotEntries }));
                            const rodeo = true;
                            if (action.isRodeo) {

                                const classEntries = entry.sidepotEntries.map((item: any) => ({
                                    id: item.id.slice(0, 24),
                                    contestantId: item.contestantId,
                                    classId: item.classId,
                                    horseName: item.name
                                }));

                                this.store.dispatch(addManyEntries({entries: classEntries}));
                            }

                            this.store.dispatch(addManyAdditionalItemPurchases({purchases: entry.additionalItemPurchases}))
                        })
                
                    }),
                    catchError((error: any) => of(getPaymentDataFailure(error))))
            )
        )
    });





}