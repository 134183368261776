import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers/AppState';
import { groupBy, mergeMap, map, Observable, toArray, from, filter } from 'rxjs';
import { selectEntries } from 'src/app/store/selectors/entries.selectors';
import { selectClassesByID } from 'src/app/store/selectors/classes.selectors';
import { selectEvent } from 'src/app/store/selectors/event.selectors';
import { selectAdditionalItems } from 'src/app/store/selectors/additional-items.selectors';
import { selectAdditionalItemPurchasesObject } from 'src/app/store/selectors/additional-item-purchases.selectors';
import { Router } from '@angular/router';
import { selectCheckoutPriceData } from 'src/app/store/selectors/checkout-price.selectors';
import { getCheckoutPrice, submitCheckout, submitCheckoutSuccess } from 'src/app/store/actions/checkout.actions';
import { Actions, ofType } from '@ngrx/effects';
import { selectSidepotEntriesEntities } from 'src/app/store/selectors/sidepotEntries.selectors';
import { selectClassDateEntriesEntities } from 'src/app/store/selectors/classDateEntries.selectors';
import { selectContestantEntries } from 'src/app/store/selectors/contestant-entries.selectors';
import { selectNonDigitalPayment } from 'src/app/store/selectors/checkout.selectors';
import { setCanNavigate } from 'src/app/store/actions/can-navigate.action';
import { selectRolloverEntriesEntities } from 'src/app/store/selectors/rolloverEntries.selectors';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {

  eventData: any = {};
  contestants$: Observable<any[]>;
  classesByContestant: any = {};
  contestantClasses: any = {};
  entriesGrouped: any = {};
  sidepotEntries: any = {};
  rolloverEntries: any = {};
  classDateEntries: any = {};
  nonDigitalPayment: boolean = false;

  additionalItems$: Observable<any[]>;
  additionalItemPurchases: any = {};

  constructor(private router: Router, private store: Store<AppState>, private actions$: Actions) {

    this.store.select(selectEvent).subscribe(eventData => {
      this.eventData = eventData;
    });

    this.contestants$ = this.store.select(selectContestantEntries);

    // Classes
    // Need Contestants and which classes said contestants have entered in
    this.store.select(selectEntries).subscribe(entries => {
      this.entriesGrouped = {};
      this.contestantClasses = {};
      
      from(entries).pipe(
        groupBy(entry => entry.classId + entry.contestantId),
        mergeMap(group => group.pipe(toArray()))
      ).subscribe(group => {

        if (!this.contestantClasses[group[0]['contestantId']]) {
          this.contestantClasses[group[0]['contestantId']] = [];
        }

        this.contestantClasses[group[0]['contestantId']].push(group[0]['classId']);
        this.entriesGrouped[group[0]['classId'] + group[0]['contestantId']] = group;

        this.classesByContestant[group[0]['contestantId']] = this.store.select(selectClassesByID(this.contestantClasses[group[0]['contestantId']]));
      });



    });

    this.store.select(selectSidepotEntriesEntities).subscribe(sidepotEntries => {
      this.sidepotEntries = sidepotEntries;
    });

    this.store.select(selectRolloverEntriesEntities).subscribe(rolloverEntries => {
      this.rolloverEntries = rolloverEntries;
    });

    this.store.select(selectClassDateEntriesEntities).subscribe(classDateEntries => {
      this.classDateEntries = classDateEntries;
    });


    // Additional Items
    this.additionalItems$ = this.store.select(selectAdditionalItems);

    this.store.select(selectAdditionalItemPurchasesObject).subscribe(itemPurchases => {
      this.additionalItemPurchases = itemPurchases;
    });

    this.store.select(selectCheckoutPriceData).subscribe(data => {
      this.store.dispatch(getCheckoutPrice({ data: data }))
    });


    //On success navigate to receipt
    this.actions$.pipe(ofType(submitCheckoutSuccess)).subscribe(_ => {
      this.router.navigate(['/receipt']);
    })

    this.store.select(selectNonDigitalPayment).subscribe(isNonDigitalPayment => {
      this.nonDigitalPayment = isNonDigitalPayment;
    });

  }

  ngOnInit(): void {
    this.store.dispatch(setCanNavigate({status: false}))
  }



}
