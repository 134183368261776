<div class="space-y-4 w-full">

    <div class="w-full">
      <div class="-mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div class="mt-2">
          <h3 class="text-md text-gray-900">
            {{item.name}} - ${{item['price']}}
          </h3>
          <p *ngIf="(item.quantity - item.amountSold) < 20" class="text-sm text-gray-900">
            {{item.quantity - item.amountSold}} remaining
          </p>
        </div>
        <div class="mt-2 flex-shrink-0">
          <div *ngIf="item['soldOut']">
            Sold Out!
          </div>
          <nav *ngIf="!item['soldOut']"
            class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <a (click)="subtractFromAdditionalItem(item._id)"
              class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
              <span class="sr-only">Minus</span>

              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                fill="currentColor">
                <path fill-rule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z"
                  clip-rule="evenodd" />
              </svg>
            </a>

            <span
              class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
              {{confirmedPurchaseData ? confirmedPurchaseData.amount : 0}}
            </span>

            <a (click)="addToAdditionalItem(item._id)"
              class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
              <span class="sr-only">Plus</span>

              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                fill="currentColor">
                <path fill-rule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clip-rule="evenodd" />
              </svg>
            </a>
          </nav>
       
        </div>
      </div>
      <div *ngIf="item['notes']" class="ml-2 mt-2">
        <h3 class="text-sm text-gray-900">{{item.notes}}</h3>
      </div>
    </div>


  </div>