import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EventService } from 'src/app/core/services/event.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/reducers/AppState';
import { selectContactInfo } from 'src/app/store/selectors/contact-info.selector';
import { updateContactInfo } from 'src/app/store/actions/contact-info.actions';
import { Observable } from 'rxjs';
import { ContactInfo } from 'src/app/core/interfaces/Contact-Info';
import { selectEvent, selectEventId } from 'src/app/store/selectors/event.selectors';
import { updatePaymentInfo } from 'src/app/store/actions/payment-info.actions';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {

  eventData: any = {};
  eventId: string = "";
  contactInfo$: Observable<ContactInfo>;
  contactInfo: FormGroup;
  states: any = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];


  constructor(private store: Store<AppState>, private _formBuilder: FormBuilder, public event: EventService, private router: Router) {
    
    this.store.select(selectEvent).subscribe(eventData => {
      this.eventData = eventData;
    });


    this.contactInfo$ = this.store.select(selectContactInfo);

    this.contactInfo = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]],
      memberNumber: [''],
      age: [''],
      district: [''],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.minLength(3), Validators.required]],
      zip: ['', [Validators.required, Validators.pattern("[0-9]{5}")]],
      phone: ['', [Validators.required, Validators.pattern("[- +()0-9]+")]],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
    });
  }

  ngOnInit(): void {

    this.store.select(selectEventId).subscribe(id => {
      this.eventId = id;
    })

  }

  backToForm() {
    this.router.navigate(['/entry']);
  }

  continueToPaymentMethodOrReview() {

    this.store.dispatch(updateContactInfo({ data: this.contactInfo.value}));

    this.store.dispatch(updatePaymentInfo({
      data: {
        nonDigitalPayment: true, name: "",
        creditCard: "",
        expirationDate: "",
        cvc: "",
        zip: ""
      }
    }))

    this.router.navigate(['/review']);
    
    // if (this.eventData.hybridEvent) {
    //   this.router.navigate(['/payment-method']);
    // } else if (this.eventData.rodeo) {
      
    //   this.store.dispatch(updatePaymentInfo({
    //     data: {
    //       nonDigitalPayment: true, name: "",
    //       creditCard: "",
    //       expirationDate: "",
    //       cvc: "",
    //       zip: ""
    //     }
    //   }))

    //   this.router.navigate(['/review']);
    // } else if (this.eventData.bbrBarrel) {
      
    //     this.store.dispatch(updatePaymentInfo({
    //       data: {
    //         nonDigitalPayment: true, name: "",
    //         creditCard: "",
    //         expirationDate: "",
    //         cvc: "",
    //         zip: ""
    //       }
    //     }))
  
    //     this.router.navigate(['/review']);
    // } else {
    //   this.router.navigate(['/payment-method']);
    // }

  }

}
