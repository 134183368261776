import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { EventClass } from "src/app/core/interfaces/EventClass";
import { getAllEventClassesSuccess } from "../actions/classes.actions";

export interface ClassesState extends EntityState<EventClass> { }

export const classesAdapter: EntityAdapter<EventClass> = createEntityAdapter<EventClass>({
  selectId: (eventClass: EventClass) => eventClass._id
});

export const initialClassesState: ClassesState = classesAdapter.getInitialState();

export const classesReducer = createReducer(
  initialClassesState,
  on(getAllEventClassesSuccess, (state, action) => classesAdapter.setAll(action.classes, state))
);

export const { selectAll, selectEntities } = classesAdapter.getSelectors();