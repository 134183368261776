  <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
    <div>
      <h3 class="text-lg leading-6 font-medium text-emerald-600">
        Notes
      </h3>
    </div>

    <div>
      <textarea id="notes" name="notes" rows="4" [(ngModel)]="body" (ngModelChange)='updateEntryNotes($event)'
        class="mt-4 py-3 block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 border-gray-300 rounded-md"></textarea>
    </div>
  </div>
