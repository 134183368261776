import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { groupBy, mergeMap, Observable, toArray, take, from } from 'rxjs';
import { AdditionalItem } from 'src/app/core/interfaces/Additional-Item';
import { ContactInfo } from 'src/app/core/interfaces/Contact-Info';
import { AppState } from 'src/app/store/reducers/AppState';
import { selectAdditionalItemPurchases } from 'src/app/store/selectors/additional-item-purchases.selectors';
import { selectAdditionalItems } from 'src/app/store/selectors/additional-items.selectors';

@Component({
  selector: 'mef-additional-items',
  templateUrl: './additional-items.component.html',
  styleUrls: ['./additional-items.component.scss']
})
export class AdditionalItemsComponent implements OnInit {

  @Input() contestant: any;
  additionalItems$: Observable<AdditionalItem[]>;
  additionalItemPurchases: any = {};

  constructor(private store: Store<AppState>) {
    this.additionalItems$ = this.store.select(selectAdditionalItems);
  }

  ngOnInit(): void {
    this.store.select(selectAdditionalItemPurchases).subscribe(purchases => {
      from(purchases).pipe(
        groupBy(itemPurchase => itemPurchase.contestantId),
        mergeMap(group => group.pipe(toArray()))
      ).subscribe(group => {

        if (group[0].contestantId == this.contestant.id) {
          this.additionalItemPurchases = Object.assign({}, ...group.map((x) => ({[x.itemId]: x})));;
        }

      });
    });
  }


}
