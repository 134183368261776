import { ContestantValidations } from 'src/app/core/interfaces/Entry';
import { AppState } from '../reducers/AppState';
import { createSelector } from '@ngrx/store';
import * as fromContestantValidationsEntries from '../reducers/contestantValidations.reducers';
import * as fromClassEntryValidationsEntries from '../reducers/classEntryValidations.reducers';

export const selectContestantsValidations = (state: AppState) => state.contestantValidations;

export const selectContestantsValidationsEntries = createSelector(
    selectContestantsValidations,
    fromContestantValidationsEntries.selectAll
);

export const selectClassEntryValidations = (state: AppState) => state.classEntryValidations;

export const selectClassEntryValidationsEntries = createSelector(
    selectClassEntryValidations,
    fromClassEntryValidationsEntries.selectAll
);
