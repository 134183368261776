import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, distinctUntilKeyChanged, Observable, Subscription, tap } from 'rxjs';
import { ContestantEntry } from 'src/app/core/interfaces/Entry';
import { EventData } from 'src/app/core/interfaces/EventData';
import { updateContestantsValid } from 'src/app/store/actions/contestants-valid.actions';
import { editContestant, removeContestantEntry } from 'src/app/store/actions/entries.actions';
import { AppState } from 'src/app/store/reducers/AppState';

@Component({
  selector: 'mef-entry-contestant',
  templateUrl: './contestant.component.html',
  styleUrls: ['./contestant.component.scss']
})
export class ContestantComponent implements OnInit, OnDestroy {

  @Input() eventData: EventData;
  @Input() item: ContestantEntry;
  @Input() contestantNumber: any = 1;
  contestantInfo: FormGroup;
  formChanges$: Subscription;
  formValid$: Subscription;

  constructor(private store: Store<AppState>, private _formBuilder: FormBuilder) { 

    this.contestantInfo = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]],
      age: ['', [Validators.required, Validators.pattern("[- +()0-9]+")]],
      memberId: [''],
      bbrMemberNumber: [''],
      district: ['']
    });

  }

  ngOnInit(): void {

    this.formChanges$ = this.contestantInfo.valueChanges.pipe(
      distinctUntilChanged((prev, curr) => (prev.name === curr.name && prev.age === curr.age && prev.memberId === curr.memberId && prev.district === curr.district && prev.bbrMemberNumber === curr.bbrMemberNumber )),
      tap(data => {
        const update = {
          id: this.item.id,
          changes: {
            name: data.name,
            age: data.age,
            memberId: data.memberId,
            bbrMemberNumber: data.bbrMemberNumber,
            district: data.district
          }
        }
        this.store.dispatch(editContestant({ update: update }));

        //Dispatch to let parent component know all forms are validß
        this.store.dispatch(updateContestantsValid({ update: { id: this.item.id, contestantInfoForm: this.contestantInfo.valid }}))
      })
    ).subscribe();
    

  }

  ngOnDestroy(): void {
    this.formChanges$.unsubscribe();
  }

  removeContestant() {
    this.store.dispatch(removeContestantEntry({ contestantId: this.item.id }))
  }

}
