import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AdditionalItem, AdditionalItemPurchase } from 'src/app/core/interfaces/Additional-Item';
import { ContestantEntry } from 'src/app/core/interfaces/Entry';

@Component({
  selector: 'mef-receipt-additional-items',
  templateUrl: './receipt-additional-items.component.html',
  styleUrls: ['./receipt-additional-items.component.scss']
})
export class ReceiptAdditionalItemsComponent implements OnInit {

  @Input() additionalItems: Observable<AdditionalItem[]>;
  @Input() additionalItemPurchases: any;
  @Input() contestant: ContestantEntry;

  constructor() { }

  ngOnInit(): void {
  }

}
