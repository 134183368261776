import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { Notes } from "src/app/core/interfaces/Notes";
import { updateNote } from "../actions/notes.actions";
import { clearEntries } from "../actions/can-navigate.action";

export interface NotesState extends EntityState<Notes> { }

export const notesAdapter: EntityAdapter<Notes> = createEntityAdapter<Notes>();

export const initialNotesState: NotesState = notesAdapter.getInitialState();

export const notesReducers = createReducer(
  initialNotesState,
  on(updateNote, (state, action) => notesAdapter.upsertOne({id: action.contestantId, contestantId: action.contestantId, body: action.body}, state)),
  on(clearEntries, (state, action) => notesAdapter.removeAll({ ...state, id: null }))
  );

export const { selectAll, selectEntities } = notesAdapter.getSelectors();