<div class="mb-16 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-8 divide-y divide-gray-200">

    <div>
        <h3 class="text-lg leading-6 font-medium text-emerald-600">
            Contact Information
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Please fill out the form below.
        </p>

        <form [formGroup]="contactInfo" [connectForm]="contactInfo$ | async" class="space-y-8 divide-y divide-gray-200" novalidate>

            <div class="space-y-8 divide-y divide-gray-200">
                <div>
                    <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div class="sm:col-span-3">
                            <label for="name" class="block text-sm font-medium text-gray-700">
                                Name
                            </label>
                            <div class="mt-1">
                                <input type="text" name="name" id="name" autocomplete="name" formControlName="name"
                                    class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md">
                            </div>
                            <div
                                *ngIf="!contactInfo.get('name')?.valid && (contactInfo.get('name')?.dirty ||contactInfo.get('name')?.touched)">
                                <p [hidden]="!contactInfo.get('name').errors?.required"
                                    class="mt-2 text-sm text-red-600" id="name-error">
                                    Name is required</p>
                                <p [hidden]="!contactInfo.get('name').errors?.minlength"
                                    class="mt-2 text-sm text-red-600" id="name-error">
                                    Name must be longer than 3 letters</p>
                                <p [hidden]="!contactInfo.get('name').errors?.pattern"
                                    class="mt-2 text-sm text-red-600" id="name-error">
                                    Name must only contain letters and spaces</p>
                            </div>

                        </div>

                        <div class="sm:col-span-3">
                            <label for="email" class="block text-sm font-medium text-gray-700">
                                Email
                            </label>
                            <div class="mt-1">
                                <input type="email" name="email" id="email" autocomplete="email" formControlName="email"
                                    class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md">
                            </div>
                            <div
                                *ngIf="!contactInfo.get('email')?.valid && (contactInfo.get('email')?.dirty ||contactInfo.get('email')?.touched)">
                                <p [hidden]="!contactInfo.get('email').errors?.required"
                                    class="mt-2 text-sm text-red-600" id="email-error">Email is required</p>
                                <p [hidden]="!contactInfo.get('email').errors?.pattern"
                                    class="mt-2 text-sm text-red-600" id="email-error">
                                    Email must be valid</p>
                            </div>
                        </div>

                        <div class="sm:col-span-3">
                            <label for="phone" class="block text-sm font-medium text-gray-700">
                                Phone
                            </label>
                            <div class="mt-1">
                                <input type="tel" name="phone" id="phone" formControlName="phone"
                                    class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md">
                            </div>
                            <div
                                *ngIf="!contactInfo.get('phone')?.valid && (contactInfo.get('phone')?.dirty ||contactInfo.get('phone')?.touched)">
                                <p [hidden]="!contactInfo.get('phone').errors?.required"
                                    class="mt-2 text-sm text-red-600" id="phone-error">Phone number is required</p>
                                <p [hidden]="!contactInfo.get('phone').errors?.pattern"
                                    class="mt-2 text-sm text-red-600" id="phone-error">
                                    Must be a valid phone number</p>
                            </div>
                        </div>

                        <!-- <div *ngIf="!event['eventData']['rodeo']" class="sm:col-span-3">
                            <label for="email" class="block text-sm font-medium text-gray-700">
                                Member Number
                            </label>
                            <div class="mt-1">
                                <div class="mt-1 flex rounded-md shadow-sm">
                                    <span
                                        class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                        #
                                    </span>
                                    <input type="number" name="memberNumber" id="memberNumber"
                                        formControlName="memberNumber"
                                        class="flex-1 focus:ring-emerald-500 focus:border-emerald-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300">
                                </div>
                            </div>
                        </div>

                        <div *ngIf="event['eventData']['rodeo']" class="sm:col-span-3">
                            <label for="age" class="block text-sm font-medium text-gray-700">
                                Age as of Nov 1st 2022
                            </label>
                            <div class="mt-1">
                                <div class="mt-1 flex rounded-md shadow-sm">
                                    <span
                                        class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                        #
                                    </span>
                                    <input type="number" name="age" id="age" formControlName="age"
                                        class="flex-1 focus:ring-emerald-500 focus:border-emerald-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300">
                                </div>
                            </div>
                        </div> -->

                        <div class="sm:col-span-6">
                            <label for="street_address" class="block text-sm font-medium text-gray-700">
                                Street address
                            </label>
                            <div class="mt-1">
                                <input type="text" name="street_address" id="street_address"
                                    autocomplete="street-address" formControlName="address"
                                    class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md">
                            </div>
                            <div
                                *ngIf="!contactInfo.get('address')?.valid && (contactInfo.get('address')?.dirty ||contactInfo.get('address')?.touched)">
                                <p [hidden]="!contactInfo.get('address').errors?.required"
                                    class="mt-2 text-sm text-red-600" id="address-error">Address is required</p>
                            </div>
                        </div>

                        <div class="sm:col-span-2">
                            <label for="city" class="block text-sm font-medium text-gray-700">
                                City
                            </label>
                            <div class="mt-1">
                                <input type="text" name="city" id="city" formControlName="city"
                                    class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md">
                            </div>
                            <div
                                *ngIf="!contactInfo.get('city')?.valid && (contactInfo.get('city')?.dirty ||contactInfo.get('city')?.touched)">
                                <p [hidden]="!contactInfo.get('city').errors?.required"
                                    class="mt-2 text-sm text-red-600" id="address-error">City is required</p>
                            </div>
                        </div>

                        <div class="sm:col-span-2">
                            <label for="state" class="block text-sm font-medium text-gray-700">
                                State
                            </label>
                            <div class="mt-1">
                                <select name="state" id="state" autocomplete="state" formControlName="state"
                                    class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md">
                                    <option *ngFor="let state of states; index as i;" value="{{state}}">{{state}}
                                    </option>
                                </select>
                            </div>
                            <div
                                *ngIf="!contactInfo.get('state')?.valid && (contactInfo.get('state')?.dirty ||contactInfo.get('state')?.touched)">
                                <p [hidden]="!contactInfo.get('state').errors?.required"
                                    class="mt-2 text-sm text-red-600" id="state-error">State is required</p>
                                <p [hidden]="!contactInfo.get('state').errors?.minlength"
                                    class="mt-2 text-sm text-red-600" id="state-error">>State is required</p>
                            </div>
                        </div>

<!-- 
                        <div *ngIf="!event['eventData']['rodeo']" class="sm:col-span-2">
                            <label for="state" class="block text-sm font-medium text-gray-700">
                                District
                            </label>
                            <div class="mt-1">
                                <input type="text" name="district" id="district" formControlName="district"
                                    class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md">
                            </div>
                        </div> -->


                        <div class="sm:col-span-2">
                            <label for="zip" class="block text-sm font-medium text-gray-700">
                                ZIP
                            </label>
                            <div class="mt-1">
                                <input type="text" name="zip" id="zip" formControlName="zip"
                                    class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md">
                            </div>
                            <div
                                *ngIf="!contactInfo.get('zip')?.valid && (contactInfo.get('zip')?.dirty ||contactInfo.get('zip')?.touched)">
                                <p [hidden]="!contactInfo.get('zip').errors?.required"
                                    class="mt-2 text-sm text-red-600" id="zip-error">
                                    Zip
                                    is required</p>
                                <p [hidden]="!contactInfo.get('zip').errors?.pattern"
                                    class="mt-2 text-sm text-red-600" id="zip-error">
                                    Must
                                    be valid zip code</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </div>


    <mef-bottom-navigation [isInvalid]="contactInfo.invalid" [backLabel]="'Back to form'"
        (onContinue)="continueToPaymentMethodOrReview()" (onBack)="backToForm()"></mef-bottom-navigation>

</div>