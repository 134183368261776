import { Action, createReducer, on } from "@ngrx/store";
import { ContactInfo } from "src/app/core/interfaces/Contact-Info";
import { updateContactInfo } from "../actions/contact-info.actions";
import { clearEntries } from "../actions/can-navigate.action";

export const initialContactInfoState: ContactInfo = {
    name: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
};

const contactInfoReducer = createReducer(
    initialContactInfoState,
    on(updateContactInfo, (state, { data }) => ({ ...state, ...data })),
    on(clearEntries, (state, action) => ({...state, ...initialContactInfoState}))
);

export function contactInfoReducers(state = initialContactInfoState, action: Action) {
    return contactInfoReducer(state, action);
}