
import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import * as fromClassesEntries from '../reducers/classEntries.reducers';
import * as fromSidePotEntries from '../reducers/sidepotEntries.reducers';
import * as fromRolloverEntries from '../reducers/rolloverEntries.reducers';
import * as fromAdditionalItemPurchases from '../reducers/additional-item-purchases.reducers';
import { ContestantEntry, HorseEntry, RolloverEntry, SidepotEntry } from 'src/app/core/interfaces/Entry';
import { AdditionalItemPurchase } from 'src/app/core/interfaces/Additional-Item';
import { selectEvent } from './event.selectors';
import { selectContactInfo } from './contact-info.selector';
import { EventData } from 'src/app/core/interfaces/EventData';
import { ContactInfo } from 'src/app/core/interfaces/Contact-Info';
import { selectClassDateEntries } from './classDateEntries.selectors';
import { selectContestantEntries } from './contestant-entries.selectors';
import { selectNonDigitalPayment } from './checkout.selectors';

export const selectClassEntriesState = (state: AppState) => state.classEntries;
export const selectSidepotEntriesState = (state: AppState) => state.sidepotEntries;
export const selectRolloverEntriesState = (state: AppState) => state.rolloverEntries;
export const selectAdditionalItemPurchasesState = (state: AppState) => state.additionalItemPurchases;

export const selectClassEntries = createSelector(
    selectClassEntriesState,
    fromClassesEntries.selectAll
);

export const selectSidepotEntries = createSelector(
    selectSidepotEntriesState,
    fromSidePotEntries.selectAll
);

export const selectRolloverEntries = createSelector(
    selectRolloverEntriesState,
    fromRolloverEntries.selectAll
);

export const selectAdditionalItemPurchases = createSelector(
    selectAdditionalItemPurchasesState,
    fromAdditionalItemPurchases.selectAll
);

export const selectCheckoutPriceData = createSelector(
    selectEvent,
    selectContactInfo,
    selectContestantEntries,
    selectClassEntries,
    selectClassDateEntries,
    selectSidepotEntries,
    selectRolloverEntries,
    selectAdditionalItemPurchases,
    selectNonDigitalPayment,
    (eventData: EventData, contactInfo: ContactInfo, contestantEntries: ContestantEntry[], classEntries: HorseEntry[], classDateSelections: any, sidepotEntries: SidepotEntry[], rolloverEntries: RolloverEntry[], additionalItemPurchases: AdditionalItemPurchase[], nonDigitalPayment: boolean) => {
        return {
            event: eventData._id,
            email: contactInfo.email,
            contestantEntries: contestantEntries,
            classes: classEntries,
            classDateSelections: classDateSelections,
            sidePots: sidepotEntries,
            rollovers: rolloverEntries,
            additionalItems: additionalItemPurchases,
            paid: eventData.paidEvent,
            nonDigitalPayment: nonDigitalPayment
        }
    }
)

export const selectCheckoutPriceResult = (state: AppState) => state.checkoutPriceResult;