<notifier-container></notifier-container>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div *ngIf="!hideMessage" class="flex items-center gap-x-6 bg-emerald-600 py-3 px-6 sm:px-3.5 sm:before:flex-1">
  <p class="text-sm leading-6 text-white">
    You can now add <strong class="font-semibold">multiple contestants</strong> on a single form.
  </p>
  <div class="flex flex-1 justify-end">
    <button (click)="toggleMessage()" type="button" class="-m-3 p-3 focus-visible:outline-offset-[-4px]">
      <span class="sr-only">Dismiss</span>
      <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path
          d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
      </svg>
    </button>
  </div>
</div>

<mef-header *ngIf="isUser"></mef-header>

<div class="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8  py-6 ">
  <router-outlet></router-outlet>
</div>