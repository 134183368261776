import { Action, createReducer, on } from "@ngrx/store";
import { getEventFeesSuccess } from "../actions/event-fees.actions";

export const initialEventFeesState: any = [];

const eventFeesReducer = createReducer(
    initialEventFeesState,
    on(getEventFeesSuccess, (state, action )=> (action.data))
);

export function eventFeesReducers(state = initialEventFeesState, action: Action) {
    return eventFeesReducer(state, action);
}