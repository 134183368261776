<div class="mb-16 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-4 space-y-8 divide-y divide-gray-200">
  <div class="space-y-6 sm:space-y-5">
    <h3 class="text-lg leading-6 font-medium text-emerald-600">
      Entry Form
    </h3>
    <p class="mt-1 max-w-2xl text-sm text-gray-500">
      Add contestants/horses to classes below as well as purchase
      additional items for sale.
    </p>

    <div class="mt-4">
      <mef-entry-contestants [eventData]="eventData"></mef-entry-contestants>
    </div>

  </div>

  <mef-bottom-navigation [isInvalid]="!isValid" [backLabel]="'Back to event'" (onContinue)="toggleQuestionModal()"
    (onBack)="backToEvent()"></mef-bottom-navigation>

</div>

<div *ngIf="questionModalVisible" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
        <div>
          <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <svg class="h-6 w-6 text-green-600"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
            </svg>
            
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Before you leave...</h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">Would you like to add an additional contestant to this form?</p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button (click)="addContestant()" type="button" class="inline-flex w-full justify-center rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 sm:col-start-2">Add Contestant</button>
          <button (click)="continueToContact()" type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">Checkout</button>
        </div>
      </div>
    </div>
  </div>
</div>