import { Action, createReducer, on } from "@ngrx/store";
import { setIsEdit } from "../actions/is-edit.action";

export const initialIsEdit = {
    status: false,
};

const isEditReducer = createReducer(
    initialIsEdit,
    on(setIsEdit, (state, { status, paymentId }) => ({ ...state, status: status, paymentId: paymentId })),
);

export function isEditReducers(state = initialIsEdit, action: Action) {
    return isEditReducer(state, action);
}