
import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import * as fromClasses from '../reducers/classes.reducers'

export const selectClassesState = (state: AppState) => state.classes;

export const selectEventClasses = createSelector(
    selectClassesState,
    fromClasses.selectAll
);

export const selectEventClassEntities = createSelector(
    selectClassesState,
    fromClasses.selectEntities
);

export const selectClassesByID = (ids: any[]) => createSelector(
    selectEventClassEntities,
    entities => ids.map(id => entities[id])
);