import { Component, Input, OnInit } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ContestantEntry, Horse } from 'src/app/core/interfaces/Entry';
import { EventData } from 'src/app/core/interfaces/EventData';
import { addHorse } from 'src/app/store/actions/entries.actions';
import { AppState } from 'src/app/store/reducers/AppState';
import { selectHorses } from 'src/app/store/selectors/horses.selectors';
import * as uuid from "uuid";

@Component({
  selector: 'app-horses',
  templateUrl: './horses.component.html',
  styleUrls: ['./horses.component.scss']
})
export class HorsesComponent implements OnInit {

  @Input() contestantData: ContestantEntry;
  @Input() eventData: EventData;
  horses$: Observable<Horse[]>;
  horsesForContestant: Horse[] = [];

  constructor(private store: Store<AppState>, private actions$: Actions) { 
  }

  ngOnInit(): void {
    this.store.select(selectHorses).subscribe(horses => {
      this.horsesForContestant = horses.filter(horse => horse.contestantId === this.contestantData.id)
    })
  }

  addHorse() {
    this.store.dispatch(addHorse({horseId: uuid.v4(), contestantId: this.contestantData.id}));
  }

  custom(index: number, item: any) {
    return index;
  }


}
