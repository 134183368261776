import { createAction, props } from '@ngrx/store';
import { EventClass } from '../../core/interfaces/EventClass';

export const getAllEventClasses = createAction(
    '[Event Classes] Get',
    props<{ id: any }>()
);

export const getAllEventClassesSuccess = createAction(
    '[Event Classes] Get Success',
    props<{ classes: EventClass[]; }>()
);

export const getEventClassesFailure = createAction(
    '[Event Classes] Get Failure',
    props<{ error: string }>()
);