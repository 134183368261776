
import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import * as fromClassesEntries from '../reducers/classEntries.reducers';
import * as fromSidePotEntries from '../reducers/sidepotEntries.reducers';
import * as fromRolloverEntries from '../reducers/rolloverEntries.reducers';
import * as fromAdditionalItemPurchases from '../reducers/additional-item-purchases.reducers';
import { ContestantEntry, HorseEntry, RolloverEntry, SidepotEntry } from 'src/app/core/interfaces/Entry';
import { AdditionalItemPurchase } from 'src/app/core/interfaces/Additional-Item';
import { selectEvent } from './event.selectors';
import { selectContactInfo } from './contact-info.selector';
import { EventData } from 'src/app/core/interfaces/EventData';
import { PaymentInfo } from 'src/app/core/interfaces/Payment-Info';
import { selectClassDateEntries } from './classDateEntries.selectors';
import { selectContestantEntries } from './contestant-entries.selectors';
import { selectAllContestantNotes } from './notes.selectors';
import { selectPaymentInfo } from './payment-info.selectors';
import { ContactInfo } from 'src/app/core/interfaces/Contact-Info';

export const selectClassEntriesState = (state: AppState) => state.classEntries;
export const selectSidepotEntriesState = (state: AppState) => state.sidepotEntries;
export const selectRolloverEntriesState = (state: AppState) => state.rolloverEntries;
export const selectAdditionalItemPurchasesState = (state: AppState) => state.additionalItemPurchases;

export const selectClassEntries = createSelector(
    selectClassEntriesState,
    fromClassesEntries.selectAll
);

export const selectSidepotEntries = createSelector(
    selectSidepotEntriesState,
    fromSidePotEntries.selectAll
);

export const selectRolloverEntries = createSelector(
    selectRolloverEntriesState,
    fromRolloverEntries.selectAll
);


export const selectAdditionalItemPurchases = createSelector(
    selectAdditionalItemPurchasesState,
    fromAdditionalItemPurchases.selectAll
);

export const selectIsEdit = (state: AppState) => state.isEdit;


export const selectCheckoutData = createSelector(
    selectEvent,
    selectContestantEntries,
    selectContactInfo,
    selectClassEntries,
    selectClassDateEntries,
    selectSidepotEntries,
    selectRolloverEntries,
    selectAdditionalItemPurchases,
    selectAllContestantNotes,
    selectPaymentInfo,
    selectIsEdit,
    (eventData: EventData, contestantEntries: ContestantEntry[], contactInfo: ContactInfo, classEntries: HorseEntry[], classDateSelections: any, sidepotEntries: SidepotEntry[], rolloverEntries: RolloverEntry[], additionalItemPurchases: AdditionalItemPurchase[], contestantNotes: any, paymentInfo: PaymentInfo, IsEdit: any) => {
        return {
            event: eventData._id,
            contestantEntries: contestantEntries,
            contactInfo: contactInfo,
            classes: classEntries,
            classDateSelections: classDateSelections,
            sidePots: sidepotEntries,
            rollovers: rolloverEntries,
            additionalItems: additionalItemPurchases,
            contestantNotes: contestantNotes,
            paid: eventData.paidEvent,
            paymentInfo: paymentInfo,
            IsEdit: IsEdit.status,
            paymentId: IsEdit.paymentId
        }
    }
)

export const selectNonDigitalPayment = (state: AppState) => state.paymentInfo.nonDigitalPayment;
 