import { Component, OnInit, Input } from '@angular/core';
import { EventClass } from 'src/app/core/interfaces/EventClass';
import { Observable } from 'rxjs';
import { EventData } from 'src/app/core/interfaces/EventData';
import { ContestantEntry } from 'src/app/core/interfaces/Entry';

@Component({
  selector: 'mef-receipt-classes',
  templateUrl: './receipt-classes.component.html',
  styleUrls: ['./receipt-classes.component.scss']
})
export class ReceiptClassesComponent implements OnInit {

  @Input() classesByContestant: any;
  @Input() sidepotEntries: any;
  @Input() classEntries: any;
  @Input() rolloverEntries: any;
  @Input() classDateEntries: any;
  @Input() eventData: EventData;
  @Input() contestant: ContestantEntry;


  constructor() {
    
  }

  ngOnInit(): void {
  }

}
