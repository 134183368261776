import { createAction, props } from "@ngrx/store";

export const setCanNavigate = createAction(
    '[Can Navigate] Update',
    props<{ status: boolean; }>()
);

export const clearEntries = createAction(
    '[Global] Clear Entries'
);
