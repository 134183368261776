<!-- Additional Items -->

<div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">

    <div>
        <h3 class="text-lg leading-6 font-medium text-emerald-600">
            Additional Items
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
            If you would like to purchase any additional
            items please select the quantity below.
        </p>
    </div>


    <div class="mt-6 divide-y divide-gray-200 rounded-br-md rounded-bl-md">

        <div *ngFor="let item of additionalItems$ | async; index as x;" class="py-4">

            <mef-additionalitem-item [contestant]="contestant" [item]="item" [purchaseData]="additionalItemPurchases[item._id]"></mef-additionalitem-item>


        </div>




    </div>

</div>