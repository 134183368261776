import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { Horse } from "src/app/core/interfaces/Entry";
import { addHorse, editHorseName, removeHorse } from "../actions/entries.actions";
import { clearEntries } from "../actions/can-navigate.action";

export interface HorsesState extends EntityState<Horse> { }

export const horsesAdapter: EntityAdapter<Horse> = createEntityAdapter<Horse>();
export const initialHorsesState: HorsesState = horsesAdapter.getInitialState();

export const horsesReducer = createReducer(
    initialHorsesState,
    on(addHorse, (state, action) => horsesAdapter.addOne({ id: action.horseId, contestantId: action.contestantId, name: '' }, state)),
    on(removeHorse, (state, action) => horsesAdapter.removeOne(action.horseId, state)),
    on(clearEntries, (state, action) => horsesAdapter.removeAll({ ...state, id: null })),
    on(editHorseName, (state, action) => horsesAdapter.updateOne(action.update, state)),
    );

export const { selectAll, selectEntities } = horsesAdapter.getSelectors();