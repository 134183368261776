import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { RolloverEntry } from "src/app/core/interfaces/Entry";
import { addRolloverEntry, addSidepotEntry, removeClassRolloverEntries, removeContestantEntry, removeEntry, removeHorse, removeRolloverEntry, removeSidepotEntry } from "../actions/entries.actions";
import { clearEntries } from "../actions/can-navigate.action";

export interface RolloverEntriesState extends EntityState<RolloverEntry> { }

export const rolloverEntriesAdapter: EntityAdapter<RolloverEntry> = createEntityAdapter<RolloverEntry>();
export const initialRolloverEntriesState: RolloverEntriesState = rolloverEntriesAdapter.getInitialState();
export const rolloverEntriesReducer = createReducer(
    initialRolloverEntriesState,
    on(addRolloverEntry, (state, action) => rolloverEntriesAdapter.addOne({ id: action.entryId + "" + action.rolloverOptionId, contestantId: action.contestantId, entryId: action.entryId, rolloverOptionId: action.rolloverOptionId, classId: action.classId, selected: true, horseId: action.horseId}, state)),
    on(removeEntry, (state, action) => rolloverEntriesAdapter.removeMany(entity => entity.entryId === action.entryId, state)),
    on(removeRolloverEntry, (state, action) => rolloverEntriesAdapter.removeOne(action.entryId + "" + action.rolloverOptionId, state)),
    on(removeContestantEntry, (state, action) => rolloverEntriesAdapter.removeMany(entity => entity.contestantId === action.contestantId, state)),
    on(removeHorse, (state, action) => rolloverEntriesAdapter.removeMany(entity => entity.horseId === action.horseId, state)),
    on(clearEntries, (state, action) => rolloverEntriesAdapter.removeAll({ ...state, id: null })),
    on(removeClassRolloverEntries, (state, action) => rolloverEntriesAdapter.removeMany(entity => entity.horseId === action.horseId, state)),
    );

export const { selectAll, selectEntities } = rolloverEntriesAdapter.getSelectors();