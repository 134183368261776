
import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import * as fromSidepotEntries from '../reducers/sidepotEntries.reducers'

export const selectSidepotEntriesState = (state: AppState) => state.sidepotEntries;

export const selectSidepotEntries = createSelector(
    selectSidepotEntriesState,
    fromSidepotEntries.selectAll
);

export const selectSidepotEntriesEntities = createSelector(
    selectSidepotEntriesState,
    fromSidepotEntries.selectEntities
);

export const selectSidepotEntriesByID = (ids: any[]) => createSelector(
    selectSidepotEntriesEntities,
    entities => ids.map(id => entities[id])
);