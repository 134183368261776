import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EventService } from './core/services/event.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './core/helpers';
import { CommonModule } from '@angular/common';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { EventComponent } from './components/event/event.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { EntryComponent } from './components/entry/entry.component';
import { ReviewComponent } from './components/review/review.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ReceiptComponent } from './components/receipt/receipt.component';
import { BottomNavigationComponent } from './components/common/bottom-navigation/bottom-navigation.component';
import { AdditionalItemsComponent } from './components/entry/additional-items/additional-items.component';
import { ClassesComponent } from './components/entry/classes/classes.component';
import { ItemComponent } from './components/entry/additional-items/item/item.component';
import { NotesComponent } from './components/entry/notes/notes.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EventEffects } from './store/effects/event.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { reducers } from './store/reducers/AppState';
import { ConnectFormDirective } from './core/directives/connectform.directive';
import { EntryEffects } from './store/effects/entry.effects';
import { SidepotComponent } from './components/entry/classes/class/sidepot/sidepot.component';
import { ClassComponent } from './components/entry/classes/class/class.component';
import { RolloverComponent } from './components/entry/classes/class/rollover/rollover.component';
import { ReceiptClassesComponent } from './components/receipt/components/receipt-classes/receipt-classes.component';
import { ReceiptAdditionalItemsComponent } from './components/receipt/components/receipt-additional-items/receipt-additional-items.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { DateComponent } from './components/entry/classes/class/date/date.component';
import { ContestantsComponent } from './components/entry/contestants/contestants.component';
import { ContestantComponent } from './components/entry/contestants/contestant/contestant.component';
import { PaymentMethodComponent } from './components/payment-method/payment-method.component';
import {NavGuard} from './core/helpers/canNavigate.guard';
import { MomentPipe } from './core/pipes/moment/moment';
import { NotifierModule } from 'angular-notifier';
import { EditComponent } from './components/edit/edit.component';
import { EditEffects } from './store/effects/edit.effects';
import { UserService } from './core/services/user.service';
import { HeaderComponent } from './components/common/header/header.component';
import { HorsesComponent } from './components/entry/horses/horses.component';
import { HorseComponent } from './components/entry/horses/horse/horse.component';

@NgModule({
    declarations: [
        AppComponent,
        ConnectFormDirective,
        EventComponent,
        ContactInfoComponent,
        EntryComponent,
        ReviewComponent,
        CheckoutComponent,
        ReceiptComponent,
        BottomNavigationComponent,
        AdditionalItemsComponent,
        ClassComponent,
        ClassesComponent,
        ItemComponent,
        NotesComponent,
        HeaderComponent,
        SidepotComponent,
        DateComponent,
        RolloverComponent,
        ReceiptClassesComponent,
        ReceiptAdditionalItemsComponent,
        ContestantsComponent,
        ContestantComponent,
        PaymentMethodComponent,
        MomentPipe,
        EditComponent,
        HorsesComponent,
        HorseComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        NotifierModule,
        CreditCardDirectivesModule,
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot([EventEffects, EntryEffects, EditEffects]),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        EventService,
        UserService,
        NavGuard
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
