
import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import * as fromClassEntries from '../reducers/classEntries.reducers';
import * as fromSidepotEntries from '../reducers/sidepotEntries.reducers'

export const selectClassEntriesState = (state: AppState) => state.classEntries;

export const selectEntries = createSelector(
    selectClassEntriesState,
    fromClassEntries.selectAll
);

export const selectClassDateEntriesEntities = createSelector(
    selectClassEntriesState,
    fromClassEntries.selectEntities
);

export const selectClassEntriesByIDs = (ids: any[]) => createSelector(
    selectClassDateEntriesEntities,
    entities => ids.map(id => entities[id])
);
