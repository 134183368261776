import { Action, createReducer, on } from "@ngrx/store";
import { ClassEntryValidations } from "src/app/core/interfaces/Entry";
import { updateContestantsValid } from "../actions/contestants-valid.actions";
import { addClassDateEntry, addEntry, removeContestantEntry, removeEntry, removeHorse, updateClassHorseName } from "../actions/entries.actions";
import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { clearEntries } from "../actions/can-navigate.action";

export interface ClassEntryValidationsState extends EntityState<ClassEntryValidations> { }

export const classEntryValidationsAdapter: EntityAdapter<ClassEntryValidations> = createEntityAdapter<ClassEntryValidations>();
export const initialClassEntryValidationsState: ClassEntryValidationsState = classEntryValidationsAdapter.getInitialState();

export const classEntryValidationsReducer = createReducer(
    initialClassEntryValidationsState,
    on(addEntry, (state, action) => classEntryValidationsAdapter.addOne({ id: action.id, classId: action.classId,  contestantId: action.contestantId, name: action.horseName ? (action.horseName.length >= 2 ? true : false) : false, horseId: action.horseId}, state)),
    on(updateClassHorseName, (state, action) => classEntryValidationsAdapter.updateOne({id: action.update.id, changes: {name: action.update.changes.horseName ? (action.update.changes.horseName.length >= 2 ? true : false) : false}}, state)),
    on(removeEntry, (state, action) => classEntryValidationsAdapter.removeOne(action.entryId, state)),
    on(clearEntries, (state, action) => classEntryValidationsAdapter.removeAll({ ...state, id: null })),
    on(removeContestantEntry, (state, action) => classEntryValidationsAdapter.removeMany(entity => entity.contestantId === action.contestantId, state)),
    on(removeHorse, (state, action) => classEntryValidationsAdapter.removeMany(entity => entity.horseId === action.horseId, state)),    
);

export const { selectAll, selectEntities } = classEntryValidationsAdapter.getSelectors();