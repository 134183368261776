<div class="mb-16 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-8 divide-y divide-gray-200">

    <div>
        <h3 class="text-lg leading-6 font-medium text-emerald-600">
            Checkout
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Your payment information is secure. Your card
            details will not be shared.
        </p>


        <div class="overflow-hidden">
            <form [formGroup]="ccForm">
                <fieldset class="mt-6">
                    <legend class="block text-sm font-medium text-emerald-700">Name on card</legend>
                    <div class="mt-1 rounded-md shadow-sm -space-y-px">
                        <div>
                            <label for="name" class="sr-only">Name on card</label>
                            <input type="text" name="name" formControlName="name" placeholder="Jane Doe"
                                class="focus:ring-emerald-500 focus:border-emerald-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300">
                        </div>
                    </div>
                </fieldset>

                <fieldset class="mt-6">
                    <legend class="block text-sm font-medium text-emerald-700">Card Details</legend>
                    <div class="mt-1 rounded-md shadow-sm -space-y-px">
                        <div>
                            <label for="card-number" class="sr-only">Card number</label>
                            <input formControlName="creditCard" name="card-number" id="card-number"
                                class="focus:ring-emerald-500 focus:border-emerald-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                                placeholder="Card number" type="tel" autocomplete="cc-number" ccNumber>
                        </div>
                        <div class="flex -space-x-px">
                            <div class="w-1/2 flex-1 min-w-0">
                                <label for="card-expiration-date" class="sr-only">Expiration date</label>
                                <input formControlName="expirationDate" name="card-expiration-date"
                                    id="card-expiration-date"
                                    class="focus:ring-emerald-500 focus:border-emerald-500 relative block w-full rounded-none rounded-bl-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                                    placeholder="MM / YY" type="tel" autocomplete="cc-exp" ccExp>
                            </div>
                            <div class="flex-1 min-w-0">
                                <label for="card-cvc" class="sr-only">CVC</label>
                                <input formControlName="cvc" name="card-cvc" id="card-cvc"
                                    class="focus:ring-emerald-500 focus:border-emerald-500 relative block w-full rounded-none rounded-br-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                                    placeholder="CVC" type="tel" autocomplete="off" ccCVC>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset class="mt-6 bg-white">
                    <legend class="block text-sm font-medium text-emerald-700">Billing address</legend>
                    <div class="mt-1 rounded-md shadow-sm -space-y-px">
                        <div>
                            <label for="postal-code" class="sr-only">ZIP / Postal code</label>
                            <input formControlName="zip" type="text" name="postal-code" id="postal-code"
                                autocomplete="postal-code"
                                class="focus:ring-emerald-500 focus:border-emerald-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                                placeholder="ZIP / Postal code">
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>

    </div>

    <mef-bottom-navigation [paidEvent]="eventData.paidEvent" [displayTotals]="true" [isInvalid]="!ccForm.valid"
    [backLabel]="'Back to review'" (onContinue)="submit()" (onBack)="backToReview()"
    [additionalText]="eventData['eventExtraText']"></mef-bottom-navigation>

</div>