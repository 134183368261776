<div class="px-2 rounded-lg border-1 border border-gray-200">
    <div class="flex items-center justify-between gap-x-6 pt-4">
        <p class="text-sm font-medium text-gray-900">
            Contestant #{{contestantNumber}}
        </p>
        <button (click)="removeContestant()" type="button"
            class="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]">
            <span class="sr-only">Dismiss</span>
            <svg class="h-5 w-5 text-gray" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                    d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
            </svg>
        </button>
    </div>
    <form [formGroup]="contestantInfo" [connectForm]="item">
        <div class="grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-8 mt-4">
            <div class="col-span-2 sm:col-span-3">
                <label for="name" class="block text-sm font-medium text-gray-700">Contestant Name</label>
                <div class="mt-1">
                    <input type="text" name="name" id="name" formControlName="name"
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm">
                </div>
                <div
                    *ngIf="!contestantInfo.get('name')?.valid && (contestantInfo.get('name')?.dirty ||contestantInfo.get('name')?.touched)">
                    <p [hidden]="!contestantInfo.get('name').errors?.required" class="mt-1 text-sm text-red-600"
                        id="name-error">
                        Name is required</p>
                    <p [hidden]="!contestantInfo.get('name').errors?.minlength" class="mt-1 text-sm text-red-600"
                        id="name-error">
                        Name must be longer than 3 letters</p>
                    <p [hidden]="!contestantInfo.get('name').errors?.pattern" class="mt-1 text-sm text-red-600"
                        id="name-error">
                        Name must only contain letters and spaces</p>
                </div>
            </div>

            <div class="col-span-2">
                <label for="age" *ngIf="!eventData.rodeo && !eventData['bbrBarrel']" class="block text-sm font-medium text-gray-700">Age</label>
                <label for="age" *ngIf="eventData.rodeo" class="block text-sm font-medium text-gray-700">Age as of
                    November 1st 2024</label>

                <label for="age" *ngIf="!eventData.rodeo && eventData['bbrBarrel']" class="block text-sm font-medium text-gray-700">Age as of
                    Aug 1st 2024</label>

                <div class="mt-1">
                    <input type="text" name="age" id="age" formControlName="age"
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm">
                </div>
                <div
                    *ngIf="!contestantInfo.get('age')?.valid && (contestantInfo.get('age')?.dirty ||contestantInfo.get('age')?.touched)">
                    <p [hidden]="!contestantInfo.get('age').errors?.required" class="mt-1 text-sm text-red-600"
                        id="name-error">
                        Age is required</p>
                    <p [hidden]="!contestantInfo.get('age').errors?.pattern" class="mt-1 text-sm text-red-600"
                        id="name-error">
                        Age must be a number</p>
                </div>
            </div>

            <div *ngIf="!eventData.rodeo && !eventData.bbrBarrel" class="col-span-1 sm:col-span-1">
                <label for="memberId" class="block text-sm font-medium text-gray-700">NBHA ID</label>
                <div class="mt-1">
                    <input type="text" name="memberId" id="memberId" formControlName="memberId"
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm">
                </div>
            </div>

            <div *ngIf="!eventData.rodeo" class="col-span-1 sm:col-span-1">
                <label *ngIf="!eventData['showStateOnContestant']" for="bbrNumber"
                    class="block text-sm font-medium text-gray-700">BBR ID</label>
                <label *ngIf="eventData['showStateOnContestant']" for="bbrNumber"
                    class="block text-sm font-medium text-gray-700">State</label>
                <div class="mt-1">
                    <input type="text" name="bbrNumber" id="bbrNumber" formControlName="bbrMemberNumber"
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm">
                </div>
            </div>

            <div *ngIf="!eventData.rodeo && !eventData.bbrBarrel" class="col-span-1 sm:col-span-2">
                <label for="district" class="block text-sm font-medium text-gray-700">District</label>
                <div class="mt-1">
                    <input type="text" name="district" id="district" formControlName="district"
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm">
                </div>
            </div>
        </div>
    </form>

    <!-- Not A Rodeo this is barrel race section -->

    <div *ngIf="!eventData.rodeo" class="mt-4">
        <app-horses [contestantData]="item" [eventData]="eventData"></app-horses>

        <mef-additional-items [contestant]="item"></mef-additional-items>

        <div class="mb-4">
            <!-- Notes section -->
            <mef-entry-notes [contestant]="item"></mef-entry-notes>
        </div>
    </div>

    <!-- This is rodeo -->
    <div *ngIf="eventData.rodeo">
        <mef-entry-classes [contestant]="item" [eventData]="eventData"
            [horse]="{id: '', contestantId: '', name: ''}"></mef-entry-classes>

        <mef-additional-items [contestant]="item"></mef-additional-items>

        <div class="mb-4">
            <!-- Notes section -->
            <mef-entry-notes [contestant]="item"></mef-entry-notes>
        </div>
    </div>


</div>