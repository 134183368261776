import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Observable, tap } from "rxjs";
import { AppState } from "src/app/store/reducers/AppState";
import { selectCanNavigate } from "src/app/store/selectors/can-navigate.selector";

@Injectable()
export class NavGuard implements CanActivate {
    constructor(private store: Store<AppState>, private route: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.pipe(
            select(selectCanNavigate),
            tap(status => {
                if (!status) {
                    const eventId = localStorage.getItem('eventId');
                    this.route.navigateByUrl('/event/' + eventId );
                }
            })
        )
    }
}