import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EventData } from 'src/app/core/interfaces/EventData';
import { addContestantEntry } from 'src/app/store/actions/entries.actions';
import { AppState } from 'src/app/store/reducers/AppState';
import { selectClassEntryValidationsEntries, selectContestantsValidationsEntries } from 'src/app/store/selectors/validations.selectors';
import { selectEvent, selectEventId } from 'src/app/store/selectors/event.selectors';
import * as uuid from "uuid";
import { ClassEntryValidations, ContestantValidations } from 'src/app/core/interfaces/Entry';
import { selectClassDateEntriesByClassEntryIds } from 'src/app/store/selectors/classDateEntries.selectors';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent {
  eventId: string = "";
  eventData: EventData;
  isValid: boolean = false;
  questionModalVisible: boolean = false;

  constructor(private store: Store<AppState>, private router: Router) {

    this.store.select(selectEventId).subscribe(id => {
      this.eventId = id;
    });

    this.store.select(selectEvent).subscribe(eventData => {
      this.eventData = eventData;
    });

    this.store.select(selectContestantsValidationsEntries).subscribe(data => {
      const checkForAllTrue = data.every((contestant: ContestantValidations) => contestant.contestantInfoForm);

      this.store.select(selectClassEntryValidationsEntries).subscribe(data => {

        this.store.select(selectClassDateEntriesByClassEntryIds(data.map(item => item.id))).subscribe(dateEntries => {

          let checkForAllTrueNames = false;

          if (!this.eventData['rodeo']) {
            checkForAllTrueNames = data.every((classEntry: ClassEntryValidations) => classEntry.name);
          } else {
            checkForAllTrueNames = true;
          }

          let checkForAllTrueDates = false;

          // Rodeos and BBR don't use a date selector
          if (!this.eventData['rodeo'] && !this.eventData['bbrBarrel']) {
            checkForAllTrueDates = dateEntries.every((dateEntry: boolean) => dateEntry);
          } else {
            checkForAllTrueDates = true;
          }

          this.isValid = checkForAllTrue && checkForAllTrueNames && checkForAllTrueDates;
        });


      });

    });


  }

  updateClassData(data: any) {
    // this.store.dispatch(updateClassEntries({ classEntries: data }))
  }

  continueToContact() {
    this.questionModalVisible = false;
    this.router.navigate(['/contact']);
  }

  backToEvent() {
    this.router.navigate(['/event', this.eventId]);
  }

  addContestant() {
    this.questionModalVisible = false;
    this.store.dispatch(addContestantEntry({ contestantId: uuid.v4() }));
  }

  toggleQuestionModal() {
    this.questionModalVisible = true;
  }

}
