
import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import * as fromAdditionalItemPurchases from '../reducers/additional-item-purchases.reducers'

export const selectAdditionalItemPurchasesState = (state: AppState) => state.additionalItemPurchases;

export const selectAdditionalItemPurchases = createSelector(
    selectAdditionalItemPurchasesState,
    fromAdditionalItemPurchases.selectAll
)

export const selectAdditionalItemPurchasesObject = createSelector(
    selectAdditionalItemPurchasesState,
    fromAdditionalItemPurchases.selectEntities
)
