import { createAction, props } from '@ngrx/store';
import { EventData } from '../../core/interfaces/EventData';

export const getEvent = createAction(
    '[Event] Get',
    props<{ id: any; }>()
);

export const getEventSuccess = createAction(
    '[Event] Get Success',
    props<{ data: EventData; }>()
);

export const getEventFailure = createAction(
    '[Event] Get Failure',
    props<{ error: string }>()
);