import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { submitCheckout } from 'src/app/store/actions/checkout.actions';
import { updatePaymentInfo } from 'src/app/store/actions/payment-info.actions';
import { AppState } from 'src/app/store/reducers/AppState';
import { selectEvent } from 'src/app/store/selectors/event.selectors';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {
  eventData: any = {};

  constructor(private router: Router, private store: Store<AppState>) {

    this.store.select(selectEvent).subscribe(eventData => {
      this.eventData = eventData;
    });

  }

  ngOnInit(): void {
  }


  creditDebit() {
    this.store.dispatch(updatePaymentInfo({
      data: {
        nonDigitalPayment: false, name: "",
        creditCard: "",
        expirationDate: "",
        cvc: "",
        zip: ""
      }
    }))
    this.router.navigate(['/review']);
  }

  cashOrCheck() {
    this.store.dispatch(updatePaymentInfo({
      data: {
        nonDigitalPayment: true, name: "",
        creditCard: "",
        expirationDate: "",
        cvc: "",
        zip: ""
      }
    }))
    this.router.navigate(['/review']);
  }

}
