
import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import * as fromNotes from '../reducers/notes.reducers'

export const selectNotesState = (state: AppState) => state.notes;

export const selectAllContestantNotes = createSelector(
    selectNotesState,
    fromNotes.selectAll
)

export const selectContestantNotes = createSelector(
    selectNotesState,
    fromNotes.selectEntities
)

export const selectNotesById = (ids: any[]) => createSelector(
    selectContestantNotes,
    entities => ids.map(id => entities[id])
);