import { Action, createReducer, on } from "@ngrx/store";
import { ContestantValidations } from "src/app/core/interfaces/Entry";
import { updateContestantsValid } from "../actions/contestants-valid.actions";
import { addContestantEntry, removeContestantEntry } from "../actions/entries.actions";
import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { clearEntries } from "../actions/can-navigate.action";

export interface ContestantValidationsState extends EntityState<ContestantValidations> { }

export const constestantValidationsAdapter: EntityAdapter<ContestantValidations> = createEntityAdapter<ContestantValidations>();
export const initialContestantValidationsState: ContestantValidationsState = constestantValidationsAdapter.getInitialState();

export const contestantValidationsReducer = createReducer(
    initialContestantValidationsState,
    on(addContestantEntry, (state, action) => constestantValidationsAdapter.addOne({ id: action.contestantId, contestantId: action.contestantId, contestantInfoForm: false}, state)),
    on(updateContestantsValid, (state, action) => constestantValidationsAdapter.upsertOne(action.update, state)),
    on(removeContestantEntry, (state, action) => constestantValidationsAdapter.removeOne(action.contestantId, state)),
    on(clearEntries, (state, action) => constestantValidationsAdapter.removeAll({ ...state, id: null }))
    );

export const { selectAll, selectEntities } = constestantValidationsAdapter.getSelectors();