import { createAction, props } from "@ngrx/store";
import { ContestantEntry, HorseEntry, SidepotEntry } from "src/app/core/interfaces/Entry";


export const addContestantEntry = createAction(
    '[Event Contestant] Add Contestant Entry',
    props<{ contestantId: string }>()
);

export const removeContestantEntry = createAction(
    '[Event Contestant] Remove Contestant Entry',
    props<{ contestantId: string }>()
);

export const addHorse = createAction(
    '[Event Horse] Add Horse',
    props<{ horseId: string, contestantId: string }>()
);

export const removeHorse = createAction(
    '[Event Horse] Remove Horse',
    props<{ horseId: string }>()
);

export const editHorseName = createAction(
    '[Event Horse] Edit Horse Name',
    props<{ update: any }>()
);

export const updateClassHorseName = createAction(
    '[Event Class] Update Horse Name',
    props<{ update: any }>()
);

export const addManyContestantEntries = createAction(
    '[Event Contestant] Add Many Contestany Entries',
    props<{ entries: ContestantEntry[] }>()
);

export const addEntry = createAction(
    '[Event Class] Add Entry',
    props<{ id: string, contestantId: string, horseId: string, classId: string, horseName: any }>()
);

export const removeEntry = createAction(
    '[Event Class] Remove Entry',
    props<{ entryId: string }>()
);

export const addManyEntries = createAction(
    '[Event Contestant] Add Many Entries',
    props<{ entries: HorseEntry[] }>()
);


export const addSidepotEntry = createAction(
    '[Event Class] Add Sidepot Entry',
    props<{ contestantId: string, horseId: string, entryId: string, sidePotId: string, classId: string, drawSelection: boolean }>()
);

export const updateSidepotEntry = createAction(
    '[Event Class] Update Sidepot Entry',
    props<{ entryId: string, sidePotId: string }>()
);

export const removeSidepotEntry = createAction(
    '[Event Class] Remove Sidepot Entry',
    props<{ entryId: string, sidePotId: string }>()
);

export const addManySidepotEntries = createAction(
    '[Event Contestant] Add Many Sidepot Entries',
    props<{ entries: SidepotEntry[] }>()
);

export const addClassDateEntry = createAction(
    '[Event Class] Add Class Date Entry',
    props<{ contestantId: string, horseId: string, entryId: string, priceId: string, classId: string, date: string}>()
);

export const updateClassDateEntry = createAction(
    '[Event Class] Update Class Date Entry',
    props<{ entryId: string, priceId: string, date: string }>()
);

export const removeClassDateEntry = createAction(
    '[Event Class] Remove Class Date Entry',
    props<{ entryId: string, priceId: string, date: string}>()
);

export const editContestant = createAction(
    '[Event Class] Edit Contestant',
    props<{ update: any }>()
);

export const editContestantName = createAction(
    '[Event Class] Edit Contestant Name',
    props<{ update: any }>()
);

export const editContestantAge = createAction(
    '[Event Class] Edit Contestant Age',
    props<{ update: any }>()
);

export const editContestantMemberNumber = createAction(
    '[Event Class] Edit Contestant Member Number',
    props<{ update: any }>()
);


export const addRolloverEntry = createAction(
    '[Event Class] Add Rollover Entry',
    props<{ contestantId: string, horseId: string, entryId: string, rolloverOptionId: string, classId: string }>()
);

export const updateRolloverEntry = createAction(
    '[Event Class] Update Rollover Entry',
    props<{ entryId: string, rolloverOptionId: string }>()
);

export const removeRolloverEntry = createAction(
    '[Event Class] Remove Rollover Entry',
    props<{ entryId: string, rolloverOptionId: string }>()
);

export const removeClassRolloverEntries = createAction(
    '[Event Class] Remove Class Rollover Entries',
    props<{ horseId: string }>()
);

export const removeSidepotDrawSelections = createAction(
    '[Event Class] Remove Sidepot Draw Selections',
    props<{ horseEntryId: string}>()
);
