import { Action, createReducer, on } from "@ngrx/store";
import { AppState } from "./AppState";
import { getEventSuccess } from "../actions/event.actions";
import { EventData } from "src/app/core/interfaces/EventData";

export const initialEventState: EventData = {
    _id: "",
    name: "",
    start: "",
    end: "",
    address: "",
    openingDateTime: "", 
    closingDateTime: "",
    paidEvent: true,
    hybridEvent: true,
    bbrBarrel: true,
    showStateOnContestant: false,
    rodeo: true,
    complete: true,
    eventExtraText: "",
    emailFooterText: "",
    cashOrCheckText: "",
    logo: ""
};

const eventReducer = createReducer(
    initialEventState,
    on(getEventSuccess, (state, { data } )=> ({ ...state, ...data })),
);

export function eventReducers(state = initialEventState, action: Action) {
    return eventReducer(state, action);
}