<div class="mb-16 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-4">

    <div>
        <h3 class="text-lg leading-6 font-medium text-emerald-600">
            Payment Method?
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Please select a payment method below.
        </p>
    </div>

    <div class="mt-6 overflow-hidden">

        <div class="max-w-lg mx-auto">
            <ul role="list" class="mt-6 px-2 rounded-lg border-2 border border-gray-200 divide-y divide-gray-200">
                <li (click)="creditDebit()">
                    <div class="relative group py-4 flex items-start space-x-3">
                        <div class="min-w-0 flex-1">
                            <div class="text-sm font-medium text-gray-900">
                                    <span class="absolute inset-0" aria-hidden="true"></span>
                                    Credit or Debit
                            </div>
                            <p class="text-sm text-gray-500">Pay for entry via Credit/Debit at the end.</p>
                        </div>
                        <div class="flex-shrink-0 self-center">
                            <!-- Heroicon name: solid/chevron-right -->
                            <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                aria-hidden="true">
                                <path fill-rule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clip-rule="evenodd" />
                            </svg>
                        </div>
                    </div>
                </li>
                <li (click)="cashOrCheck()">
                    <div class="relative group py-4 flex items-start space-x-3">
                        <div class="min-w-0 flex-1">
                            <div class="text-sm font-medium text-gray-900">
                                    <span class="absolute inset-0" aria-hidden="true"></span>
                                    Cash or Check
                            </div>
                            <p class="text-sm text-gray-500">{{eventData['cashOrCheckText']}}</p>
                        </div>
                        <div class="flex-shrink-0 self-center">
                            <!-- Heroicon name: solid/chevron-right -->
                            <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                aria-hidden="true">
                                <path fill-rule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clip-rule="evenodd" />
                            </svg>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>