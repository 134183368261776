import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { AdditionalItemPurchase } from "src/app/core/interfaces/Additional-Item";
import { addManyAdditionalItemPurchases, createOrIncrementPurchase, decrementPurchase } from "../actions/additional-items.actions";
import * as uuid from "uuid";
import { removeContestantEntry } from "../actions/entries.actions";
import { clearEntries } from "../actions/can-navigate.action";

export interface AdditionalItemPurchasesState extends EntityState<AdditionalItemPurchase> { }

export const additionalItemsAdapter: EntityAdapter<AdditionalItemPurchase> = createEntityAdapter<AdditionalItemPurchase>();

export const initialAdditionalItemPurchasesState: AdditionalItemPurchasesState = additionalItemsAdapter.getInitialState();

export const additionalItemPurchasesReducer = createReducer(
  initialAdditionalItemPurchasesState,
  on(createOrIncrementPurchase, (state, action) => additionalItemsAdapter.upsertOne(action.purchase, state)),
  on(decrementPurchase, (state, action) => additionalItemsAdapter.upsertOne(action.purchase, state)),
  on(decrementPurchase, (state, action) => additionalItemsAdapter.removeMany(entity => entity.amount === 0, state)),
  on(removeContestantEntry, (state, action) => additionalItemsAdapter.removeMany(entity => entity.contestantId === action.contestantId, state)),
  on(clearEntries, (state, action) => additionalItemsAdapter.removeAll({ ...state, id: null })),
  on(addManyAdditionalItemPurchases, (state, action) => additionalItemsAdapter.addMany(action.purchases, state)),
  );

export const { selectAll, selectEntities } = additionalItemsAdapter.getSelectors();