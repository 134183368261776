import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { updateNote } from 'src/app/store/actions/notes.actions';
import { AppState } from 'src/app/store/reducers/AppState';
import { selectContestantNotes, selectNotesById } from 'src/app/store/selectors/notes.selectors';
import { Notes } from 'src/app/core/interfaces/Notes';

@Component({
  selector: 'mef-entry-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  @Input() contestant: any;
   body: string;

  constructor(private store: Store<AppState>) { 
  }

  ngOnInit(): void {
    this.store.select(selectNotesById([this.contestant.id])).subscribe((data: any) => {
      if (data.length > 0) {
        this.body = data[0]?.body;
      }
  })
  }

  updateEntryNotes($event: any) {
    this.store.dispatch(updateNote({ contestantId: this.contestant.id, body: this.body }))
  }

}
