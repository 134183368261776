<div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8" *ngIf="eventData">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img src="https://api.myentryform.com/logo/{{eventData.logo}}" alt="" class="mx-auto h-48 w-auto">
    </div>
  
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="space-y-6">
            <div>
                <h2 class="mt-6 text-center text-xl font-extrabold text-gray-900">
                  Edit
                </h2>
              </div>
          <div>
            <h2 class="mt-6 text-center text-xl font-extrabold text-gray-900">
              {{eventData.name}}
            </h2>
          </div>
          <div>
            <p class="mt-2 text-center text-sm text-gray-600">
              Accepting online entries {{eventData.openingDateTime | date:'longDate'}} at
              {{eventData.openingDateTime | date:'shortTime'}} until
              {{eventData.closingDateTime | date:'longDate'}} at
              {{eventData.closingDateTime | date:'shortTime'}}
            </p>
          </div>

          <div *ngIf="!isLive()">
            <button
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
              Entries are closed!
            </button>
          </div>
  
          <div *ngIf="isLive()">
            <button (click)="continueToEntryForm()"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
              Online Entry
            </button>
          </div>
        
        </div>
      </div>
    </div>
  </div>