import { createAction, props } from '@ngrx/store';
import { AdditionalItem, AdditionalItemPurchase } from 'src/app/core/interfaces/Additional-Item';

export const getAllEventAdditionalItemsSuccess = createAction(
    '[Event Additional Items] Get Success',
    props<{ items: AdditionalItem[]; }>()
);

export const createOrIncrementPurchase = createAction(
    '[Event Additional Items] Create Or Increment Purchase',
    props<{ purchase: AdditionalItemPurchase; }>()
);

export const decrementPurchase = createAction(
    '[Event Additional Items] Decrement Purchase',
    props<{ purchase: AdditionalItemPurchase; }>()
);

export const addManyAdditionalItemPurchases = createAction(
    '[Event Additional Items] Add Many',
    props<{ purchases: AdditionalItemPurchase[]; }>()
);