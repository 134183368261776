
import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import * as fromContestantEntries from '../reducers/contestantEntries.reducers';

export const selectContestantEntriesState = (state: AppState) => state.contestantEntries;

export const selectContestantEntries = createSelector(
    selectContestantEntriesState,
    fromContestantEntries.selectAll
);
