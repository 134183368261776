<div class="mb-16 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-4">

    <div>
        <h3 class="text-lg leading-6 font-medium text-emerald-600">
            Review
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Please review purchases before proceeding to
            checkout.
        </p>

        <div class="mt-6 overflow-hidden space-y-4">
            <div *ngFor="let contestant of contestants$ | async"
                class="px-2 rounded-lg border-1 border border-gray-200">
                <div class="pt-4">
                    <h3 class="text-lg leading-6 font-medium text-emerald-600">
                        {{contestant.name}}
                    </h3>
                </div>
                <ul role="list">

                    <mef-receipt-classes [contestant]="contestant" [eventData]="eventData" [classesByContestant]="classesByContestant"
                        [classEntries]="entriesGrouped" [sidepotEntries]="sidepotEntries" [rolloverEntries]="rolloverEntries"
                        [classDateEntries]="classDateEntries"></mef-receipt-classes>

                    <!-- Loop contestants to get id's -->
                    <mef-receipt-additional-items [contestant]="contestant" [additionalItems]="additionalItems$"
                        [additionalItemPurchases]="additionalItemPurchases"></mef-receipt-additional-items>

                </ul>
            </div>
        </div>

    </div>

    <mef-bottom-navigation [paidEvent]="eventData.paidEvent && !nonDigitalPayment" [rodeo]="eventData.rodeo" [rodeo]="eventData.bbrBarrel" [displayTotals]="true" [isInvalid]="false"
        [backLabel]="'Back to form'" (onContinue)="continueToCheckout()" (onBack)="backToForm()"
        [additionalText]="eventData['eventExtraText']"></mef-bottom-navigation>

</div>