import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AdditionalItem, AdditionalItemPurchase } from 'src/app/core/interfaces/Additional-Item';
import { createOrIncrementPurchase, decrementPurchase } from 'src/app/store/actions/additional-items.actions';
import { AppState } from 'src/app/store/reducers/AppState';

@Component({
  selector: 'mef-additionalitem-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {

  @Input() contestant: any;
  @Input() item: AdditionalItem;

  _confirmedPurchaseData: any;

  @Input() set purchaseData(data: AdditionalItemPurchase) {
    if(data) {
     this._confirmedPurchaseData = data;
    }
  }

  get confirmedPurchaseData(): AdditionalItemPurchase {
    return this._confirmedPurchaseData;
  }

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
  }

  addToAdditionalItem(itemId: string) {
    let amount = 0;
    let blocked = false;
    if (this.confirmedPurchaseData) {
      amount = this.confirmedPurchaseData.amount;

      if ((this.confirmedPurchaseData.amount) == (this.item.quantity - this.item.amountSold)) {
        blocked = true;
      }
    }

    if (!blocked) {
      this.store.dispatch(createOrIncrementPurchase({ purchase: { id: this.contestant.id + "" + itemId, contestantId: this.contestant.id, itemId: itemId, amount: amount + 1 } }));
    }

  }

  subtractFromAdditionalItem(itemId: string) {
    if (this.confirmedPurchaseData && this.confirmedPurchaseData.amount > 0) {

        const amount = this.confirmedPurchaseData.amount;
        this.store.dispatch(decrementPurchase({ purchase: { id: this.contestant.id + "" + itemId, contestantId: this.contestant.id, itemId: itemId, amount: amount - 1 } }));

        if (this.confirmedPurchaseData.amount == 1) {
          this._confirmedPurchaseData = null;
        }
    }
  }


}
