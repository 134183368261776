import { Action, createReducer, on } from "@ngrx/store";
import { getCheckoutPriceSuccess } from "../actions/checkout.actions";

export const initialCheckoutPriceState: any = {};

const eventReducer = createReducer(
    initialCheckoutPriceState,
    on(getCheckoutPriceSuccess, (state, action )=> (action.data))
);

export function checkoutPriceReducers(state = initialCheckoutPriceState, action: Action) {
    return eventReducer(state, action);
}