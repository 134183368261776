<div class="mt-4">

    <div class="flex flex-auto flex-col w-full">
        <div class="mb-4">
            <h4 class="font-medium text-gray-900">
                Additional Items
            </h4>
        </div>

        <div>
            <section aria-labelledby="additional-item-heading" class="pb-4">
                <dl *ngFor="let item of additionalItems | async">
                    <div *ngIf="additionalItemPurchases[contestant.id + item._id]?.amount" class="flex items-center justify-between">
                        <dt class="text-sm text-gray-600">{{item.name}} x {{additionalItemPurchases[contestant.id + item._id]?.amount}}</dt>
                        <dd class="text-sm font-medium text-gray-900">{{item.price * additionalItemPurchases[contestant.id + item._id]?.amount | currency}}</dd>
                    </div>
                </dl>
            </section>
        </div>

    </div>
</div>