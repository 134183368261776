import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EventClass } from '../interfaces/EventClass';
import { Observable, of } from 'rxjs';
import { CheckoutPriceResponse } from '../interfaces/Checkout-Price';
import { ContactInfo } from '../interfaces/Contact-Info';
import { EventData } from '../interfaces/EventData';
import { PaymentData } from '../interfaces/Entry';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EntryService {

  public states = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'];

  constructor(private http: HttpClient) { }

  add(data: any) {

    console.log('Data', data);

    return this.http.post<Event[]>(environment.apiUrl + 'entry2/', data);

  }

  edit(id: any, data: any) {

    return this.http.put<Event[]>(environment.apiUrl + 'entry/' + id, data);

  }

  getCheckoutPrice(data: any) {

    return this.http.post<CheckoutPriceResponse>(environment.apiUrl + 'entry2/checkoutPrice', data);

  }

  getEntry(id: any) {

    return this.http.get<PaymentData>(environment.apiUrl + 'entry2/editPayment/' + id);

  }
}
