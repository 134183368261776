import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, Subscription, tap } from 'rxjs';
import { ContestantEntry, Horse } from 'src/app/core/interfaces/Entry';
import { EventData } from 'src/app/core/interfaces/EventData';
import { editHorseName, removeHorse } from 'src/app/store/actions/entries.actions';
import { AppState } from 'src/app/store/reducers/AppState';

@Component({
  selector: 'app-horse',
  templateUrl: './horse.component.html',
  styleUrls: ['./horse.component.scss']
})
export class HorseComponent implements OnInit {

  @Input() eventData: EventData;
  @Input() item: ContestantEntry;
  @Input() horse: Horse;
  @Input() horseNumber: any = 1;
  horseInfo: FormGroup;
  formChanges$: Subscription;
  formValid$: Subscription;

  constructor(private store: Store<AppState>, private _formBuilder: FormBuilder) { 

    this.horseInfo = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]],
    });

  }

  ngOnInit(): void {

    this.formChanges$ = this.horseInfo.valueChanges.pipe(
      distinctUntilChanged((prev, curr) => (prev.name === curr.name)),
      tap(data => {
        const update = {
          id: this.horse.id,
          changes: {
            name: data.name,
          }
        }
        this.store.dispatch(editHorseName({ update: update }));

        // //Dispatch to let parent component know all forms are valid
        // this.store.dispatch(updateContestantsValid({ update: { id: this.item.id, contestantInfoForm: this.contestantInfo.valid }}))
      })
    ).subscribe();
    

  }

  ngOnDestroy(): void {
    this.formChanges$.unsubscribe();
  }

  removeHorse() {
    this.store.dispatch(removeHorse({ horseId: this.horse.id }))
  }

}

