import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { ContestantEntry } from "src/app/core/interfaces/Entry";
import { addContestantEntry, editContestant, addEntry, editContestantAge, editContestantMemberNumber, editContestantName, editHorseName, removeContestantEntry, removeEntry, addManyContestantEntries } from "../actions/entries.actions";
import { clearEntries } from "../actions/can-navigate.action";

export interface ContestantEntriesState extends EntityState<ContestantEntry> { }

export const contestantEntriesAdapter: EntityAdapter<ContestantEntry> = createEntityAdapter<ContestantEntry>();
export const initialContestantEntriesState: ContestantEntriesState = contestantEntriesAdapter.getInitialState();
export const contestantEntriesReducer = createReducer(
    initialContestantEntriesState,
    on(addContestantEntry, (state, action) => contestantEntriesAdapter.addOne({ id: action.contestantId, name: '', age: undefined, district: '', memberId: '', bbrMemberNumber: '' }, state)),
    on(addManyContestantEntries, (state, action) => contestantEntriesAdapter.addMany(action.entries, state)),
    on(removeContestantEntry, (state, action) => contestantEntriesAdapter.removeOne(action.contestantId, state)),
    on(editContestant, (state, action) => contestantEntriesAdapter.updateOne(action.update, state)),
    on(editContestantName, (state, action) => contestantEntriesAdapter.updateOne(action.update, state)),
    on(editContestantAge, (state, action) => contestantEntriesAdapter.updateOne(action.update, state)),
    on(editContestantMemberNumber, (state, action) => contestantEntriesAdapter.updateOne(action.update, state)),
    on(clearEntries, (state, action) => contestantEntriesAdapter.removeAll({ ...state, id: null }))
    );

export const { selectAll, selectEntities } = contestantEntriesAdapter.getSelectors();