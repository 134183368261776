import { createAction, props } from '@ngrx/store';

export const getEventFees = createAction(
    '[Event Fees] Get Event Fees',
    props<{ id: string; }>()
);

export const getEventFeesSuccess = createAction(
    '[Event Fees] Get Event Fees Success',
    props<{ data: any; }>()
);

export const getEventFeesFailure = createAction(
    '[Event Fees] Get Event Fees Failure',
    props<{ error: string }>()
);