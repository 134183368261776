import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './core/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'MyEntryForm';
  hideMessage = true;
  isUser: boolean = false;

  constructor(public router: Router, private userservice: UserService) {
    this.userservice.getUser();
    if (this.userservice.activeUser.getValue()) {
      this.isUser = true;
    }
  
  }

  toggleMessage() {
    this.hideMessage = !this.hideMessage;
  }


}
