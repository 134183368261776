import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';
import { User } from '../interfaces/user';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private userservice: UserService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.userservice.activeUser.getValue();

        // if (currentUser) {
        //     return true;
        // } else {
        //     // not logged in so redirect to login page with the return url
        //     window.location.href = "https://admin.myentryform.com";
        //     return false;
        // }

        return true;


    }
}